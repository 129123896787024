
export async function waitRefresh(seconds: number): Promise<void> {
    // Create a delay function
    const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

    // Convert seconds to milliseconds and wait
    await delay(seconds * 1000);

    // Refresh the page
    window.location.reload();
}
