import React from "react";
import PrimaryButton from "../UI/PrimaryButton/PrimaryButton";

import './AllowancePopup.scss'
type Props = {
	title: string;
	balanceAllowance?: number;
	onClickAdd?: () => void;
}
const AllowancePopup: React.FC<Props> = ({title,balanceAllowance, onClickAdd}) => {
	return (
		<div className="allowanceAdd mt-4">
          <span className="topCornerLeftDots"></span>
          <span className="topCornerRightDots"></span>
          <span className="bottomCornerLeftDots"></span>
          <span className="bottomCorneRightDots"></span>
          <label>{title}</label>
          <b>{balanceAllowance ? balanceAllowance : "----"} $FORT</b>
		  <PrimaryButton
              text="Add"
			  width="109px"
              onClick={onClickAdd}
              className={`${balanceAllowance ? "secondaryColor" : "disabled-btn secondaryColor"}`}
            />
        </div>
	)
}

export default AllowancePopup