import React, { useState } from "react";
import RulesAndRisk from "../../Components/RulesAndRisk/RulesAndRisk";
import JoinGameContainer from "./Components/JoinGameContainer";

import './JoinGame.scss'
type Props = {}
const JoinGame: React.FC<Props> = () => {
	const [ isRulesConfirmed, setRulesConfirmed] = useState<boolean>(false)

	const onConfirmRules = () => {
		console.log('cn')
		setRulesConfirmed(true)
	}
	console.log('isRulesConfirmed', isRulesConfirmed)
	return (
		<div className="joinGame">
			{
				isRulesConfirmed ? <JoinGameContainer /> : <RulesAndRisk onClick={() => onConfirmRules()} title={"Rules & Risks"} description={["At the start of each match, 9 of your PiCO cards will be transferred into the smart contract. All cards, except those burned, will be returned to the players at the end of the match.", "Defeated cards are burned permanently and will not be returned at the end of the match. Players have 60 seconds to pick a card per turn. If a player does not make a selection within this time, a random card will be chosen for them. The game room fee will be returned only to the winning player. The losing player will also lose their fees.", "By clicking confirm, you acknowledge and accept these terms and the associated risks."]} />
			}
		</div>
	)
}

export default JoinGame