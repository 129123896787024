import { combineReducers } from "redux";
import profileSlide from "./redux/profileSlice";
// import your reducers here

const rootReducer = combineReducers({
  profileSlide: profileSlide,
  // your reducers here
});

export default rootReducer;
