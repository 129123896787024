import React from "react";
import NavCards from "../../../../assets/images/cardsBtn.png";
import "./CardOpened.scss";

type Props = {
  onClickCard: (id: number) => void;
  active: boolean;
  handleActive: () => void;
  cards: {
    tokenId: number;
    categoryId: number;
    disabled: boolean;
    isMutant: boolean;
  }[];
  isMyTurn?: boolean;
};

const CardsOpened = React.forwardRef<HTMLDivElement, Props>(
  ({ onClickCard, active = false, handleActive, cards, isMyTurn }, ref) => {
    const getCardImage = (categoryId: number, isMutant: boolean) => {
      return isMutant
        ? `/mutantsvgs/${categoryId}.svg`
        : `/cardsvgs/${categoryId}.svg`; // Path to the card image based on categoryId and mutation status
    };

    return (
      <div
        className={`${active ? "cardOpened slide-right" : "cardOpened"} ${
          !isMyTurn ? "inactive" : ""
        }`}
        ref={ref}
      >
        <div className="cardsOpenIcon">
          <img src={NavCards} alt="Nav Cards" onClick={() => handleActive()} />
        </div>
        <div className="notYourTurn">Not your turn</div>
        <div className="cardsList">
          <div className="cards">
            {cards.map((card, index) => (
              <div
                className={`card ${card.disabled ? "disabled" : ""}`} // Apply a 'disabled' class if the card is not playable
                key={index}
                onClick={() => !card.disabled && onClickCard(card.tokenId)} // Only trigger onClick if the card is playable
              >
                <img
                  src={getCardImage(card.categoryId, card.isMutant)}
                  alt={`Card ${card.tokenId}`}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
);

export default CardsOpened;
