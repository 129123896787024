import React, { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import './Layout.scss'
type Props = {
	children: ReactNode;
}
const Layout: React.FC<Props> = ({children}) => {
	const location = useLocation();

	return (
		<div className="mainLayout">
		{location.pathname !== "/gameplay" && <Navbar />}
		{children}
		{/* {walletsModal && <WalletsConnectModal />}
		{mainLoader && <Loader />} */}
	  </div>
	)
}

export default Layout