import Logo from "../../../assets/images/logo_Last.svg";
import LogoText from "../../../assets/images/logo-text.png";

import "./MainLogo.scss";

type Props = {
  isTextAvailable?: boolean;
};
const MainLogo: React.FC<Props> = ({ isTextAvailable = true }) => {
  return (
    <div className="logo">
      <img src={Logo} alt="Logo" />
      <br />
      {isTextAvailable && <img src={LogoText} alt="Logo Text" />}
      {/* <p className="mt-4 font-pixel-millennium text-white leading-none logo-text-styles">
            Pixel Chain
            <br />
            Olympus
          </p> */}
    </div>
  );
};

export default MainLogo;
