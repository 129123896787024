import React from "react";
import Modal from 'react-modal';
import './Modal.scss'
type Props = {
	open: boolean;
	onClose: (status: boolean) => void;
	children?: any;
	width?: string;
	className?: string;
}



const ModalContainer: React.FC<Props> = ({open, onClose, children, width, className}) => {


	  function afterOpenModal() {
		// references are now sync'd and can be accessed.
	  }
	
	  function closeModal() {
		onClose(false);
	  }
	  const customStyles = {
		content: {
		  maxWidth: width ?? 367,
		  
		},
	  };
	return (
		<Modal
        isOpen={open}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
		className={`modalContainer ${className}`}
		style={customStyles}
      >
        {children}
      </Modal>
	)
}

export default ModalContainer
