import React, { useEffect, useState } from "react";
import { ReactComponent as Card1Icon } from "../../../../assets/images/gameBoard/card1.svg";
import { ReactComponent as Card2Icon } from "../../../../assets/images/gameBoard/card2.svg";
import { ReactComponent as Card3Icon } from "../../../../assets/images/gameBoard/card3.svg";
import BurdCard from "../../../../assets/images/burning_card.gif";
import CardMutagin from "../../../../assets/images/card-mutating.gif";

import Power2 from "../../../../assets/images/comboCards/2.gif";
import Power3 from "../../../../assets/images/comboCards/3.gif";
import Power4 from "../../../../assets/images/comboCards/4.gif";
import Power5 from "../../../../assets/images/comboCards/5.gif";
import Power6 from "../../../../assets/images/comboCards/6.gif";
import Power7 from "../../../../assets/images/comboCards/7.gif";
import Power8 from "../../../../assets/images/comboCards/8.gif";
import Power9 from "../../../../assets/images/comboCards/9.gif";
import Power10 from "../../../../assets/images/comboCards/10.gif";
import ComboPowerup from "../../../../assets/images/comboCards/powerup.gif";
import ComboPowerup2 from "../../../../assets/images/comboCards/powerup2.gif";

import "./PlayersCards.scss";
import PrimaryButton from "../../../../Components/Common/UI/PrimaryButton/PrimaryButton";
import cardMetadata from "../../../../web3/cardMetadata.json"; // Import metadata for strength and rarity

type Props = {
  playedCards: any[];
  cardTypes: number[];
  mutantStatuses: boolean[]; // Prop for tracking mutant statuses
  isCreator: boolean;
  forceOpponentHand: () => void;
  isForcePlayer: boolean;
  comboSize: any;
  setComboSize: (size: any) => void;
  burnedCardsList: any;
};

// Mapping for power-up images
const comboPowerImages: { [key: number]: string } = {
  2: Power2,
  3: Power3,
  4: Power4,
  5: Power5,
  6: Power6,
  7: Power7,
  8: Power8,
  9: Power9,
  10: Power10,
};

const PlayersCards: React.FC<Props> = ({
  playedCards,
  cardTypes,
  mutantStatuses,
  isCreator,
  forceOpponentHand,
  isForcePlayer,
  comboSize,
  setComboSize,
  burnedCardsList,
}) => {
  const [isBurned, setIsBurned] = useState<boolean>(false);
  const [isMutating, setIsMutaging] = useState<boolean>(false);
  const [burnIndex, setBurnIndex] = useState<number>(-1); // Track the current index of burned cards

  useEffect(() => {
    // If there are burned cards, start burning them one by one
    if (burnedCardsList?.length > 0 && burnIndex < burnedCardsList.length) {
      const timeout = setTimeout(() => {
        setBurnIndex((prevIndex) => prevIndex + 1);
      }, 1000); // Show the next card every 1 second

      return () => clearTimeout(timeout); // Clear timeout on cleanup
    }
  }, [burnIndex, burnedCardsList]);

  // Get the image for the combo power level (from 2 to 10)
  const getComboImage = (comboLevel: number): string | undefined => {
    return comboPowerImages[comboLevel] || undefined; // Return undefined instead of null
  };

  const getCardImage = (categoryId: number, isMutant: boolean) => {
    return isMutant
      ? `/mutantsvgs/${categoryId}.svg`
      : `/cardsvgs/${categoryId}.svg`;
  };

  const getCardIcon = (slotIndex: number) => {
    switch (slotIndex) {
      case 0: // Weapons should have this icon
        return <Card3Icon key="card3" />;
      case 1: // Characters should have this icon
        return <Card1Icon key="card1" />;
      case 2: // Shields should have this icon
        return <Card2Icon key="card2" />;
      default:
        return null;
    }
  };

  // Function to calculate combo power for each player
  const calculateComboPower = (
    cards: any[],
    types: number[],
    mutantStatuses: boolean[]
  ) => {
    const characterCard = cards.find((_, index) => types[index] === 0); // Character card (type 0)
    const weaponCard = cards.find((_, index) => types[index] === 1); // Weapon card (type 1)
    const shieldCard = cards.find((_, index) => types[index] === 2); // Shield card (type 2)

    if (!characterCard)
      return { weaponComboPower: null, shieldComboPower: null };

    const isMutantCharacter =
      mutantStatuses[cards.findIndex((_, index) => types[index] === 0)];

    const characterMetadata = cardMetadata.find(
      (meta) => meta.id === characterCard.catId
    );
    const weaponMetadata = weaponCard
      ? cardMetadata.find((meta) => meta.id === weaponCard.catId)
      : null;
    const shieldMetadata = shieldCard
      ? cardMetadata.find((meta) => meta.id === shieldCard.catId)
      : null;

    if (!characterMetadata)
      return { weaponComboPower: null, shieldComboPower: null };

    const characterRarity = characterMetadata.rarity;
    let weaponComboPower: number | null = null;
    let shieldComboPower: number | null = null;

    // If character is a mutant, add fixed +5 strength to both weapon and shield
    if (isMutantCharacter) {
      weaponComboPower = weaponMetadata ? weaponMetadata.strength + 5 : null;
      shieldComboPower = shieldMetadata ? shieldMetadata.strength + 5 : null;
      return { weaponComboPower, shieldComboPower };
    }

    // Combo calculation for weapon
    if (weaponMetadata && weaponMetadata.rarity === characterRarity) {
      const bonus = getComboBonus(characterRarity, 2);
      weaponComboPower = weaponMetadata.strength + bonus;
    }

    // Combo calculation for shield
    if (shieldMetadata && shieldMetadata.rarity === characterRarity) {
      const bonus = getComboBonus(characterRarity, 2);
      shieldComboPower = shieldMetadata.strength + bonus;
    }

    // Check if both weapon and shield match character rarity for 3-combo
    if (
      weaponMetadata &&
      shieldMetadata &&
      weaponMetadata.rarity === characterRarity &&
      shieldMetadata.rarity === characterRarity
    ) {
      const bonus = getComboBonus(characterRarity, 3);
      weaponComboPower = weaponMetadata.strength + bonus;
      shieldComboPower = shieldMetadata.strength + bonus;
    }

    return { weaponComboPower, shieldComboPower };
  };

  // Get combo bonus based on rarity and combo type
  const getComboBonus = (rarity: number, comboType: number) => {
    if (rarity === 0) return comboType === 2 ? 1 : 3;
    if (rarity === 1) return comboType === 2 ? 2 : 4;
    if (rarity === 2) return comboType === 2 ? 3 : 5;
    return 0;
  };

  const isBurnedCard = (card: any, index: number) => {
    return (
      burnedCardsList?.length &&
      burnedCardsList?.find((item: any) => item.categoryId === card) &&
      index <= burnIndex // Only show up to the current burn index
    );
  };

  const getCardElements = (
    playerCards: any[],
    playerCardTypes: number[],
    playerMutantStatuses: boolean[],
    combo: any
  ) => {
    const cardSlots: (JSX.Element | null)[] = [null, null, null]; // Three slots: [weapon, character, shield]
    const { weaponComboPower, shieldComboPower } = calculateComboPower(
      playerCards,
      playerCardTypes,
      playerMutantStatuses
    ); // Calculate combo power

    playerCards.forEach((card, index) => {
      const typeIndex = playerCardTypes[index];
      const isMutant = playerMutantStatuses[index]; // Get the mutant status for the current card
      const slotIndex = typeIndex === 0 ? 1 : typeIndex === 1 ? 0 : 2; // Map type to the correct slot

      if (!cardSlots[slotIndex] && card && card.catId !== undefined) {
        const comboPower =
          typeIndex === 1
            ? weaponComboPower
            : typeIndex === 2
            ? shieldComboPower
            : null; // Apply combo power
        let totalComboSize = 0;
        if (combo.weaponComboPower && combo.shieldComboPower) {
          setComboSize(3);
          totalComboSize = 3;
        } else if (combo.weaponComboPower || combo.shieldComboPower) {
          setComboSize(2);
          totalComboSize = 2;
        }

        cardSlots[slotIndex] = (
          <div className="card" key={index}>
            {getCardIcon(slotIndex)}
            <img
              src={getCardImage(card?.catId, isMutant)} // Use the mutant status here
              alt={`Card ${card?.tokenId}`}
              className="playingCard"
            />

            {!isBurnedCard(card?.catId, index) &&
              typeof comboPower === "number" &&
              comboPower >= 2 && (
                <div className="comboEffect">
                  <img
                    src={totalComboSize === 2 ? ComboPowerup : ComboPowerup2}
                    className="comboCard"
                    alt="Combo Card"
                  />

                  <img
                    src={getComboImage(comboPower)} // Display combo power image
                    className="comboNumber"
                    alt={`Combo Power ${comboPower}`}
                  />
                </div>
              )}
            {isBurnedCard(card?.catId, index) && (
              <img className="burnCard" src={BurdCard} alt="Burn Card" />
            )}

            {isMutating && (
              <img className="burnCard" src={CardMutagin} alt="Burn Card" />
            )}
          </div>
        );
      }
    });
    return cardSlots.map((slot, index) => (
      <div className="card" key={index}>
        {getCardIcon(index)}
        {slot && slot.props && slot.props.children
          ? slot.props.children // Render all children
          : null}
      </div>
    ));
    // return cardSlots.map((slot, index) => (
    //   <div className="card" key={index}>
    //     {getCardIcon(index)}
    //     {slot && slot.props && slot.props.children && slot.props.children.length > 1
    //       ? slot.props.children[1]
    //       : null} {/* Conditionally render the <img> if it exists */}
    //   </div>
    // ));
  };

  const creatorCards = playedCards
    .slice(0, 6)
    .filter((_, index) => index % 2 === 0); // Even indices for creator
  const creatorCardTypes = cardTypes
    .slice(0, 6)
    .filter((_, index) => index % 2 === 0);
  const creatorMutantStatuses = mutantStatuses
    .slice(0, 6)
    .filter((_, index) => index % 2 === 0); // Filter mutant statuses for creator cards

  const joinerCards = playedCards
    .slice(0, 6)
    .filter((_, index) => index % 2 !== 0); // Odd indices for joiner
  const joinerCardTypes = cardTypes
    .slice(0, 6)
    .filter((_, index) => index % 2 !== 0);
  const joinerMutantStatuses = mutantStatuses
    .slice(0, 6)
    .filter((_, index) => index % 2 !== 0); // Filter mutant statuses for joiner cards

  const joinerComboPower = calculateComboPower(
    joinerCards,
    joinerCardTypes,
    joinerMutantStatuses
  );

  // Directly calculate the combo power for the creator
  const creatorComboPower = calculateComboPower(
    creatorCards,
    creatorCardTypes,
    creatorMutantStatuses
  );
  return (
    <div className="playerCards">
      <div className="cardBox joinerCards">
        {/* Opponent's Cards */}
        {getCardElements(
          isCreator ? joinerCards : creatorCards,
          isCreator ? joinerCardTypes : creatorCardTypes,
          isCreator ? joinerMutantStatuses : creatorMutantStatuses, // Pass mutant statuses
          isCreator ? joinerComboPower : creatorComboPower
        )}
      </div>
      {isForcePlayer && (
        <div className="cardBox forceOpponentHand">
          <PrimaryButton disabled={!isForcePlayer} onClick={forceOpponentHand}>
            Force Opponent's Hand
          </PrimaryButton>
        </div>
      )}
      <div className="cardBox">
        {/* Player's Cards */}
        {getCardElements(
          isCreator ? creatorCards : joinerCards,
          isCreator ? creatorCardTypes : joinerCardTypes,
          isCreator ? creatorMutantStatuses : joinerMutantStatuses, // Pass mutant statuses
          isCreator ? creatorComboPower : joinerComboPower
        )}
      </div>
    </div>
  );
};

export default PlayersCards;
