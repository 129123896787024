import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Home from "./Pages/Home/Home";
import HostGame from "./Pages/HostGame/HostGame";

import "./App.scss";
import Layout from "./Components/Layout/Layout";
import YourCards from "./Pages/YourCards/YourCards";
import YourTrophies from "./Pages/YourTrophies/YourTrophies";
import JoinGame from "./Pages/JoinGame/JoinGame";
import CardShop from "./Pages/CardShop/CardShop";
import ProfileSettings from "./Pages/ProfileSettings/ProfileSettings";
import AwaitingOpponent from "./Pages/AwaitingOpponent/AwaitingOpponent";
import GameBoard from "./Pages/GameBoard/GameBoard";
import MutantResearch from "./Pages/MutantResearch/MutantResearch";
import { Toaster } from "react-hot-toast";
import DeckBuilder from "./Pages/DeckBuilder/DeckBuilder";
import { useDispatch, useSelector } from "react-redux";
import { setPlayerRulesModalOpen } from "./store/redux/profileSlice";
import InfoModal from "./Components/Common/Modals/InfoModal/InfoModal";

const App: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const playerCards = useSelector(
    (state: any) => state.profileSlide.playerCards
  );
  const hasPermissionToPlay = useSelector(
    (state: any) => state.profileSlide.hasPermissionToPlay
  );
  const isPlayerRulesModalOpen = useSelector(
    (state: any) => state.profileSlide.isPlayerRulesModalOpen
  );

  const playerRulesModalDetailsContent = useSelector(
    (state: any) => state.profileSlide.playerRulesModalDetailsContent
  );

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/host-game" element={<HostGame />} />
        <Route path="/my-cards" element={<YourCards />} />
        <Route path="/my-career" element={<YourTrophies />} />
        <Route path="/join-game" element={<JoinGame />} />
        <Route path="/card-shop" element={<CardShop />} />
        <Route path="/profile-settings" element={<ProfileSettings />} />
        <Route path="/awaiting-opponent" element={<AwaitingOpponent />} />
        <Route path="/game-board" element={<GameBoard />} />
        <Route path="/mutant-research-center" element={<MutantResearch />} />
        <Route path="/deck-builder" element={<DeckBuilder />} />
      </Routes>

      <Toaster
        position="bottom-right"
        toastOptions={{
          className: "text-white",
          style: {
            border: "1px solid #707070",
            background: "black",
            padding: "16px",
            color: "#707070",
          },
        }}
      />
      <InfoModal
        openModal={isPlayerRulesModalOpen}
        closeModal={() => dispatch(setPlayerRulesModalOpen(false))}
        title="GET YOUR DECK IN CHECK!"
        description={playerRulesModalDetailsContent}
        mainButtonText={"CARD SHOP"}
        handleBack={() => {
          navigate("/my-cards");
          dispatch(setPlayerRulesModalOpen(false));
        }}
        secondButtonText="MY CARDS"
        hideMainButton={false}
        handleMainButton={() => {
          navigate("/card-shop");
          dispatch(setPlayerRulesModalOpen(false));
        }}
      />
    </Layout>
  );
};

export default App;
