import React from "react";
import PrimaryButton from "../Common/UI/PrimaryButton/PrimaryButton";
import { Link } from "react-router-dom";

import './RulesAndRisks.scss'
type Props = {
	title: string;
	description: string[];
	onClick: () => void;
}
const RulesAndRisk: React.FC<Props> = ({title, description, onClick}) => {
	return (
		<div className="rulesAndRisk">
			<h1>{title}</h1>
			{description?.map((item: string) => (
				<p>{item}</p>
			))}
			<div className="btn-groups">
				<PrimaryButton text="Confirm" onClick={() => onClick()} />
				<Link to="/">
				<PrimaryButton text="Cancel" />
				</Link>
			</div>
		</div>
	)
}

export default RulesAndRisk