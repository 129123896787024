import React, { ReactNode } from "react";
import "./PrimaryButton.scss";
export interface ButtonProps {
  text?: ReactNode;
  className?: string;
  onClick?: (data: any) => void;
  disabled?: boolean;
  ariaLabel?: string;
  width?: string;
  children?: ReactNode;
}

//hover:bg-[#3D3D3D] btn-hover-shadow hover:text-[#ABABAC] active:bg-[#F0EBC0] active:text-[#1A1A1A] raise
const PrimaryButton: React.FC<ButtonProps> = ({
  text,
  className,
  onClick,
  disabled,
  ariaLabel,
  width,
  children,
}) => {
  return (
    <button
      style={{ width: width ?? 244 }}
      className={`primary-button-styles raise  ${
        className ?? ""
      }  relative primary-button`}
      onClick={onClick}
      disabled={disabled}
      aria-label={ariaLabel}
    >
      {text}
      <span className="topCornerLeftDots"></span>
      <span className="topCornerRightDots"></span>
      <span className="bottomCornerLeftDots"></span>
      <span className="bottomCorneRightDots"></span>
      <span className="extraShadow"></span>
      {children}
    </button>
  );
};

export default PrimaryButton;
