import React from "react";
import Modal from "../../Modal/Modal";
import PrimaryButton from "../../UI/PrimaryButton/PrimaryButton";

import "./InfoModal.scss";
export type ModalState = boolean;

type Props = {
  openModal: boolean;
  title: string;
  description: any;
  closeModal?: () => void;
  handleMainButton?: () => void;
  handleBack?: () => void;
  mainButtonText?: string;
  secondButtonText?: string;
  hideMainButton?: boolean;
  hideSecondButton?: boolean;
};

const InfoModal: React.FC<Props> = ({
  openModal,
  title,
  description,
  closeModal,
  handleMainButton,
  handleBack,
  mainButtonText,
  hideMainButton = true,
  secondButtonText,
  hideSecondButton = false,
}) => {
  if (!openModal) return null;
  return (
    <Modal open={openModal} onClose={() => closeModal}>
      <div className="congratulations">
        <div className="closeModal" onClick={closeModal}>
          x
        </div>
        <p className="leader-board-title" style={{ color: "white" }}>
          {title}
        </p>
        <p className="description">{description}</p>
        <div className="buttons">
          {!hideMainButton && (
            <PrimaryButton
              text={mainButtonText ?? "CARD SHOP"}
              onClick={handleMainButton}
              //    width="180px"
            />
          )}
          {!hideSecondButton && (
            <PrimaryButton
              text={secondButtonText ?? "BACK"}
              //   width="180px"
              onClick={handleBack}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default InfoModal;
