import React from 'react';


	const WonIcon = ({ fill }: any) => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="15.888" height="14.753" viewBox="0 0 15.888 14.753">
  <g id="Group_532" data-name="Group 532" transform="translate(0 -0.001)">
    <path id="Path_4873" data-name="Path 4873" d="M1.135,0V1.136H2.269V2.27H0v3.4H1.135V3.405H2.269V4.54H3.4V5.675H4.539V6.81H5.674V7.945H6.809v3.4H5.674v1.135H4.539v1.135H3.4v1.135h9.079V13.619H11.348V12.484H10.214V11.349H9.079v-3.4h1.135V6.81h1.135V5.675h1.135V4.54h1.135V3.405h1.135v2.27h1.135V2.27h-2.27V1.136h1.135V0Z" fill={fill}/>
    <path id="Path_4874" data-name="Path 4874" d="M64.085,192.256v1.135h2.27v-1.135h-2.27Z" transform="translate(-61.816 -185.446)" fill={fill}/>
    <path id="Path_4875" data-name="Path 4875" d="M320.425,192.256v1.135h2.27v-1.135h-2.27Z" transform="translate(-309.076 -185.446)" fill={fill}/>
    <rect id="Rectangle_5276" data-name="Rectangle 5276" width="1.135" height="1.135" transform="translate(1.135 5.675)" fill={fill}/>
    <rect id="Rectangle_5277" data-name="Rectangle 5277" width="1.135" height="1.135" transform="translate(13.618 5.675)" fill={fill}/>
  </g>
</svg>

  );
};

export default WonIcon;
