// src/utils/discordWebhook.ts

import axios from "axios";

const DISCORD_WEBHOOK_URL =
  "https://discord.com/api/webhooks/1305620004145725441/JyB44to2niciESoVSIA_vrOFTQ1rhH7OSlqYKSuyrm-COYTCakecrJ4O2pqRnTmJHnPW";

export const sendDiscordNotification = async (username: string) => {
  // Format the message content
  const content = `
  🎉 **A new game room has been created by ${username}!** 🎉

  🕹️ **Join us now and be part of the action!**
  👉 [Click here to join the game room](https://www.pixelchain.games/join-game)

  *Get ready to compete and have fun!* 🌟
  `;

  try {
    await axios.post(DISCORD_WEBHOOK_URL, { content });
    console.log("Notification sent to Discord!");
  } catch (error) {
    console.error("Error sending notification to Discord:", error);
  }
};
