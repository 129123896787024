import React from 'react';


const LeftRightArrow = () => {
  return (
	<svg id="Right_Button" data-name="Right Button" xmlns="http://www.w3.org/2000/svg" width="12" height="20" viewBox="0 0 12 20">
	<rect id="Rectangle_3461" data-name="Rectangle 3461" width="4" height="4" transform="translate(12 12) rotate(180)" fill="#fff"/>
	<rect id="Rectangle_3462" data-name="Rectangle 3462" width="4" height="4" transform="translate(8 16) rotate(180)" fill="#fff"/>
	<rect id="Rectangle_3463" data-name="Rectangle 3463" width="4" height="4" transform="translate(8 8) rotate(180)" fill="#fff"/>
	<rect id="Rectangle_3464" data-name="Rectangle 3464" width="4" height="4" transform="translate(4 4) rotate(180)" fill="#fff"/>
	<rect id="Rectangle_3465" data-name="Rectangle 3465" width="4" height="4" transform="translate(4 20) rotate(180)" fill="#fff"/>
	</svg>
  );
};

export default LeftRightArrow;