import React from "react";
import WonIcon from "../SVGComponents/WonIcon";
import './YourTrophiesList.scss'; // Assuming you have a separate stylesheet

interface TrophyData {
  currentRoom: string;
  gamesWon: string;
  gamesPlayed: string;
  sfortWon: string;
  cardsBurnt: string;
  cardsLost: string;
}

interface TrophyProps {
  data: TrophyData;
}

const YourTrophiesList = ({ data }: TrophyProps) => {
  return (
    <div className="trophy-content">
      <div className="trophy-item">
        <span className="trophy-label">Current Room:</span> {data.currentRoom}
      </div>
      <div className="trophy-item">
        <span className="trophy-label">Games Won:</span> {data.gamesWon}
      </div>
      <div className="trophy-item">
        <span className="trophy-label">Games Played:</span> {data.gamesPlayed}
      </div>
      <div className="trophy-item">
        <span className="trophy-label">Sfort Won:</span> {data.sfortWon} $FORT
      </div>
      <div className="trophy-item">
        <span className="trophy-label">Cards Burnt:</span> {data.cardsBurnt}
      </div>
      <div className="trophy-item">
        <span className="trophy-label">Cards Lost:</span> {data.cardsLost}
      </div>
    </div>
  );
};

export default YourTrophiesList;
