export function shortenAddress(address: string | undefined, charsToShow: number = 4, breakChar: string = '...'): string {
  if (!address) return '';
  if (address.length <= charsToShow * 2 + breakChar.length) {

      return address;
  }
  const start = address.slice(0, charsToShow);
  const end = address.slice(-charsToShow);
  return start + breakChar + end;
}
