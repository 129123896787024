import React, { useState } from "react";
import Modal from "../../Modal/Modal";
import PrimaryButton from "../../UI/PrimaryButton/PrimaryButton";

import './HowToPlay.scss'
export type ModalState = boolean;

type Props = {
  openModal: boolean;
  title: string;
  closeModal?: () => void;
  handleBack?: () => void;
};
const HowToPlay: React.FC<Props> = ({
  openModal,
  title,
  closeModal,
  handleBack,
}) => {
  return (
    openModal ? (
      <Modal open={true} onClose={() => {}} className="howToPlay">
        <div className="howToPlay">
          <div className="closeModal" onClick={() => closeModal}>
            x
          </div>
          <p className="leader-board-title">
            {title}
          </p>
          <div className="video">
            <div className="relative">
              <video controls className="object-cover" >
                <source
                  src={"https://www.w3schools.com/html/mov_bbb.mp4"}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <div className="flex flex-col gap-4 items-center mt-8 pb-5">
            <PrimaryButton text="BACK" onClick={() => handleBack} />
          </div>
        </div>
      </Modal>
    ) : <></>
  );
};

export default HowToPlay;
