import React, { ChangeEventHandler } from 'react';

import './InputRange.scss'
interface InputRangePropsType {
  percentage: number,
  approveAmt: string,
  handleChange: ChangeEventHandler<HTMLInputElement>,
  setPercentage: (percentage: number) => void,
}
const InputRange = ({ percentage, approveAmt, handleChange, setPercentage }: InputRangePropsType) => {
  return (
    <div className="w-full p-4 hostGameInputRangeSlider">
      <div className='allowanceSelected'>
        <span>{approveAmt}</span>
		<span>$FORT</span>
      </div>

      <div className='inputBox'>
		<input
			className="range-input"
			type="range"
			min="0"
			max="100"
			step="1"
			value={percentage}
			onChange={handleChange}
		/>
		<span className="topCornerLeftDots"></span>
                  <span className="topCornerRightDots"></span>
                  <span className="bottomCornerLeftDots"></span>
                  <span className="bottomCorneRightDots"></span>
	  </div>

      <div className="percents">
        <span className='-ml-2 md:-ml-4 box' onClick={() => setPercentage(0)}>0%</span>
        <span className='box' onClick={() => setPercentage(25)}>25%</span>
        <span className='box' onClick={() => setPercentage(50)}>50%</span>
        <span className='box' onClick={() => setPercentage(75)}>75%</span>
        <span className='-mr-4 md:-mr-6 box' onClick={() => setPercentage(100)}>100%</span>
      </div>
    </div>
  );
};

export default InputRange;