import React, { useState } from 'react';
import ModalContainer from '../../Common/Modal/Modal';
import InputRange from '../../Common/UI/InputRange/InputRange';
import PrimaryButton from '../../Common/UI/PrimaryButton/PrimaryButton';
import './AllowanceModal.scss';

interface Props {
  open: boolean;
  onClose: (status: boolean) => void;
  balance?: any;
  userTokenBalance?: any;
  onApprove: () => Promise<void>;
  approveAmt: string; // Receive approveAmt as a prop
  setApproveAmt: React.Dispatch<React.SetStateAction<string>>; // Receive setApproveAmt as a prop
}

  const AllowanceModal: React.FC<Props> = ({ open, onClose, balance, userTokenBalance, approveAmt, setApproveAmt, onApprove }) => {
  const [percentage, setPercentage] = useState(0.0);

  const setAllowancePercentage = (percentage: number) => {
      setPercentage(Number(percentage));
      onApproverAmtChange(percentage.toString());
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setPercentage(Number(event.target.value));
      onApproverAmtChange(event.target.value);
  };

  const onApproverAmtChange = (value: string) => {
      try {
          let amountBn = (Number(balance) * Number(value)) / 100;
          if (Math.round(amountBn) > Number(balance)) {
              amountBn = Math.floor(amountBn);
          } else {
              amountBn = Math.round(amountBn);
          }
          setApproveAmt(amountBn.toString()); // Call setApproveAmt from props to update the main state
      } catch (e) {
          setApproveAmt('0');
      }
  };

  return (
      <ModalContainer open={open} onClose={onClose} width="397px" className="allowanceModal">
          <p className="mainTitle hostGameTitle pb-8">ALLOWANCE</p>
          <InputRange
              percentage={percentage}
              approveAmt={approveAmt} // Bind approveAmt to the input range
              handleChange={handleChange}
              setPercentage={setAllowancePercentage}
          />
          <div className="available">
              <div className="text-center w-full">
                  <p className="font-dinosaur font-bold text-base text-[#717070]">Available</p>
                  <p className="font-dinosaur font-light text-white allowanceFort">
                      {userTokenBalance} $FORT
                  </p>
              </div>
          </div>
          <div className="setAllowance">
              <PrimaryButton
                  text="Set Allowance"
                  className="secondaryColor"
                  onClick={onApprove}
              />
              <PrimaryButton
                  text="Back"
                  className=""
                  onClick={() => onClose(false)}
              />
          </div>
      </ModalContainer>
  );
};

export default AllowanceModal;
