import React from "react";
import './Title.scss';
type Props = {
	title: string
}
const Title: React.FC<Props> = ({title}) => {
	return (
		<h1 className="mainTitle">{title}</h1>
	)
}

export default Title