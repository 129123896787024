import { ethers } from 'ethers';
import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import {
  provider as defaultProvider,
  signer as defaultSigner,
  cardContract as defaultCardContract,
  shopContract as defaultShopContract,
  pico1v1Contract as defaultPico1v1Contract,
  sfortContract as defaultSFortContract,
  stakingContract as defaultStakingContract,
  viewerContract as defaultViewerContract
} from './contracts';

interface Web3ContextType {
  provider: ethers.providers.Web3Provider | undefined;
  signer: ethers.providers.JsonRpcSigner | undefined;
  cardContract: ethers.Contract | undefined;
  shopContract: ethers.Contract | undefined;
  pico1v1Contract: ethers.Contract | undefined;
  viewerContract: ethers.Contract | undefined;
  sfortContract: ethers.Contract | undefined;
  stakingContract: ethers.Contract | undefined;
  isConnected: boolean;
  myAddress: string | undefined;
  connectWallet: () => Promise<void>;
}

const Web3Context = createContext<Web3ContextType | undefined>(undefined);

export const Web3Provider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [web3State, setWeb3State] = useState<Web3ContextType>({
    provider: undefined,
    signer: undefined,
    cardContract: undefined,
    shopContract: undefined,
    pico1v1Contract: undefined,
    viewerContract: undefined,
    sfortContract: undefined,
    stakingContract: undefined,
    isConnected: false,
    myAddress: undefined,
    connectWallet: async () => {},
  });

  const connectWallet = async () => {
    try {
      if ((window as any).ethereum) {
        const ethereumProvider = (window as any).ethereum;
        const web3Provider = new ethers.providers.Web3Provider(ethereumProvider);
        await ethereumProvider.request({ method: 'eth_requestAccounts' });
        const userSigner = web3Provider.getSigner();
        const userAddress = await userSigner.getAddress();

        // Update the web3State with the connected wallet and contracts
        setWeb3State({
          provider: web3Provider,
          signer: userSigner,
          cardContract: defaultCardContract,
          shopContract: defaultShopContract,
          pico1v1Contract: defaultPico1v1Contract,
          viewerContract: defaultViewerContract,
          sfortContract: defaultSFortContract,
          stakingContract: defaultStakingContract,
          isConnected: true,
          myAddress: userAddress,
          connectWallet,
        });
      } else {
        console.error('No wallet provider found');
      }
    } catch (error) {
      console.error('Error connecting wallet:', error);
    }
  };

  useEffect(() => {
    const initializeWeb3 = async () => {
      if ((window as any).ethereum && !web3State.isConnected) {
        await connectWallet();
      }
    };

    initializeWeb3();

    // Listen for account changes
    if ((window as any).ethereum) {
      (window as any).ethereum.on('accountsChanged', async (accounts: string[]) => {
        if (accounts.length > 0) {
          await connectWallet(); // Reconnect with new account
        } else {
          setWeb3State((prevState) => ({
            ...prevState,
            isConnected: false,
            myAddress: undefined,
            signer: undefined,
          }));
        }
      });

      // Listen for chain/network changes
      (window as any).ethereum.on('chainChanged', async () => {
        window.location.reload(); // Reload to avoid any network inconsistency
      });
    }
  }, []);

  return (
    <Web3Context.Provider value={web3State}>
      {children}
    </Web3Context.Provider>
  );
};

export const useWeb3 = (): Web3ContextType => {
  const context = useContext(Web3Context);
  if (!context) {
    throw new Error('useWeb3 must be used within a Web3Provider');
  }
  return context;
};
