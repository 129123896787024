import React, { useState, useEffect, useCallback } from "react";
import "./CardShop.scss";
import Title from "../../Components/Common/Title/Title";
import ShieldPackIcon from "../../assets/images/cardshop/shields.png";
import CharacterPackIcon from "../../assets/images/cardshop/characters.png";
import WeaponPackIcon from "../../assets/images/cardshop/weapons.png";
import Full18CardIcon from "../../assets/images/cardshop/fullDeck.png";
import PrimaryButton from "../../Components/Common/UI/PrimaryButton/PrimaryButton";
import SingleIcon from "../../assets/images/cardshop/singleIcon.svg";
import MultipleIcon from "../../assets/images/cardshop/multipleIcon.svg";
import AllowancePopup from "../../Components/Common/AllowancePopup/AllowancePopup";
import { Link, useNavigate } from "react-router-dom";
import AllowanceModal from "../../Components/Modals/AllowanceModal/AllowanceModal";
import { useWeb3 } from "../../web3/Web3Context";
import { ethers, ContractReceipt } from "ethers";
import {
  cardContract,
  shopContract,
  sfortContract,
  grabberContract,
} from "../../web3/contracts";
import cardMetadata from "../../web3/cardMetadata.json";
import { notify } from "../../utils/customToast";
import Loader from "../../Components/Common/Loader/Loader";
import CardsMinted from "../../Components/Common/Modals/CardsMinted/CardsMinted";

interface TotalQty {
  name: string;
  qty: number;
  price: number;
  sgbprice: number;
  id: number;
}

const CardShop = () => {
  const { myAddress, isConnected, provider } = useWeb3();
  const navigate = useNavigate();
  const [totalQty, setTotalQty] = useState<TotalQty[]>([
    {
      name: "Shield pack (9 Cards)",
      qty: 1,
      price: 0, // Will be set by fetchPrices
      sgbprice: 0, // Will be set by fetchPrices
      id: 2,
    },
    {
      name: "Weapon pack (9 Cards)",
      qty: 1,
      price: 0, // Will be set by fetchPrices
      sgbprice: 0, // Will be set by fetchPrices
      id: 1,
    },
    {
      name: "Character pack (9 Cards)",
      qty: 1,
      price: 0, // Will be set by fetchPrices
      sgbprice: 0, // Will be set by fetchPrices
      id: 0,
    },
    {
      name: "Full Deck (27 Cards)",
      qty: 1,
      price: 0, // Will be set by fetchPrices
      sgbprice: 0, // Will be set by fetchPrices
      id: 100,
    },
  ]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [characterCount, setCharacterCount] = useState(0);
  const [weaponCount, setWeaponCount] = useState(0);
  const [shieldCount, setShieldCount] = useState(0);
  const [allowance, setAllowance] = useState<string>("0");
  const [approveAmt, setApproveAmt] = useState<string>("0");

  const [sfortBalance, setSfortBalance] = useState<string>("0");
  const [sgbBalance, setSgbBalance] = useState<string>("0");
  const [cardBalance, setCardBalance] = useState<string>("0");
  const [mainLoader, setMainLoader] = useState<boolean>(false);
  const [cardMinted, setCardMinted] = useState<boolean>(false);
  const [mintedCards, setmintedCards] = useState<any>(undefined);
  const [totalRarity, setTotalRarity] = useState<any>(undefined);
  const [rarityPercentages, setRarityPercentages] = useState<{
    rarity0: number;
    rarity1: number;
    rarity2: number;
  }>({ rarity0: 0, rarity1: 0, rarity2: 0 });
  const [rarityTotalNumber, setRarityTotalNumber] = useState<any>(undefined);
  const getQty = (name: string) =>
    totalQty.find((item: TotalQty) => item.name === name);

  const onIncrease = (id: number) => {
    const totalQtyUpdated = [...totalQty];
    const cardIndex = totalQty.findIndex((item: TotalQty) => item.id === id);
    if (totalQtyUpdated[cardIndex].qty < 10) {
      totalQtyUpdated[cardIndex].qty += 1;
    }
    setTotalQty(totalQtyUpdated);
  };

  const onDecrease = (id: number) => {
    const totalQtyUpdated = [...totalQty];
    const cardIndex = totalQty.findIndex((item: TotalQty) => item.id === id);
    if (totalQtyUpdated[cardIndex].qty > 1) {
      totalQtyUpdated[cardIndex].qty -= 1;
    }
    setTotalQty(totalQtyUpdated);
  };

  const fetchCardCounts = useCallback(async () => {
    if (myAddress && cardContract) {
      try {
        let characterCount = 0;
        let weaponCount = 0;
        let shieldCount = 0;

        const balance = await cardContract.balanceOf(myAddress);
        await fetchRarityPercentages();
        const tokenIds: any[] = [];
        if (balance && balance.gt(0)) {
          await getCardsBatch(tokenIds, 0, balance.toNumber());
        }

        tokenIds.forEach((card: any) => {
          const metadata = cardMetadata.find(
            (meta) => meta.id === card.cardType
          );
          if (metadata) {
            switch (metadata.type) {
              case 0:
                characterCount++;
                break;
              case 1:
                weaponCount++;
                break;
              case 2:
                shieldCount++;
                break;
            }
          }
        });

        setCharacterCount(characterCount);
        setWeaponCount(weaponCount);
        setShieldCount(shieldCount);
      } catch (e) {
        console.error("Failed to fetch card counts", e);
      }
    }
  }, [myAddress]);

  const getCardsBatch = async (
    tokenIds: any[],
    from: number,
    maxLength: number
  ) => {
    if (!cardContract) return;
    try {
      const countPerTrait = await grabberContract?.getCardsBatch(
        myAddress,
        from
      );
      for (let i = 0; i < countPerTrait[0].length; i++) {
        const cardType = Number(countPerTrait[1][i]);
        const metadata = cardMetadata.find((meta) => meta.id === cardType);
        if (metadata) {
          tokenIds.push({
            id: countPerTrait[0][i],
            cardType: cardType,
            image: `/assets/images/cardsvgs/${cardType}.svg`,
            name: metadata.name,
            rarity: metadata.rarity,
            cattype: metadata.type,
            strength: metadata.strength,
          });
        }
      }

      if (from + 40 < maxLength) {
        await getCardsBatch(tokenIds, from + 40, maxLength);
      }
    } catch (e) {
      console.error("Error fetching card batch", e);
    }
  };

  const fetchPrices = useCallback(async () => {
    if (!shopContract) return;
    try {
      const [
        basicPackFee,
        premiumPackFee,
        sfortBasicPackFee,
        sfortPremiumPackFee,
      ] = await Promise.all([
        shopContract.basicPackFee(),
        shopContract.premiumPackFee(),
        shopContract.sfortBasicPackFee(),
        shopContract.sfortPremiumPackFee(),
      ]);

      // Update totalQty with fetched prices
      setTotalQty((prevQty) =>
        prevQty.map((item) => {
          if (item.id === 100) {
            return {
              ...item,
              price: parseFloat(sfortPremiumPackFee.toString()),
              sgbprice: parseFloat(premiumPackFee.toString()),
            };
          } else {
            return {
              ...item,
              price: parseFloat(sfortBasicPackFee.toString()),
              sgbprice: parseFloat(basicPackFee.toString()),
            };
          }
        })
      );
    } catch (e) {
      console.error("Error fetching prices", e);
    }
  }, []);

  const fetchAllowancesAndBalances = useCallback(async () => {
    if (!myAddress || !sfortContract || !shopContract) return;
    try {
      const [sfortBalance, sgbBalance, allowance] = await Promise.all([
        sfortContract.balanceOf(myAddress),
        provider?.getBalance(myAddress),
        sfortContract.allowance(myAddress, shopContract.address),
      ]);

      if (sfortBalance) {
        setSfortBalance(
          Math.floor(
            parseFloat(ethers.utils.formatEther(sfortBalance))
          ).toString()
        );
      }

      if (sgbBalance) {
        setSgbBalance(
          Math.floor(
            parseFloat(ethers.utils.formatEther(sgbBalance))
          ).toString()
        );
      }

      if (allowance) {
        setAllowance(
          Math.floor(parseFloat(ethers.utils.formatEther(allowance))).toString()
        );
      }
    } catch (e) {
      console.error("Error fetching allowances and balances", e);
    }
  }, [myAddress]);

  const onMint = async (qty: number, mintTypeId: number, isSFort: boolean) => {
    if (!shopContract) return;
    try {
      let price;

      if (!isSFort) {
        price = ethers.utils.parseUnits(
          mintTypeId === 100
            ? String(getQty("Full Deck (27 Cards)")?.sgbprice)
            : String(getQty("Shield pack (9 Cards)")?.sgbprice),
          18
        );
      } else {
        price = ethers.BigNumber.from(
          mintTypeId === 100
            ? String(getQty("Full Deck (27 Cards)")?.price)
            : String(getQty("Shield pack (9 Cards)")?.price)
        );

      }

      if (price.isZero()) {
        notify("Choose Card Pack First!", "error");
        return;
      }

      const amount = price.mul(qty);

      if (isSFort) {
        if (ethers.utils.parseEther(sfortBalance).lt(amount)) {
          notify("Insufficient $FORT Balance!", "error");
          return;
        }
      } else {
        if (ethers.utils.parseEther(sgbBalance).lt(amount)) {
          notify("Insufficient SGB Balance!", "error");
          return;
        }
      }

      setMainLoader(true);

      if (isSFort) {
        const currentAllowance = ethers.utils.parseEther(allowance);
        if (currentAllowance.lt(amount)) {
          notify(
            "Insufficient allowance. Please increase your allowance.",
            "error"
          );
          setMainLoader(false);
          return;
        }
      }

      const gasPrice = await provider?.getGasPrice();
      const adjustedGasPrice = gasPrice?.add(
        ethers.BigNumber.from("10000000000")
      );
      let gasLimit = Math.min(qty * 4500000, 8000000);
      if (mintTypeId === 100) {
        gasLimit = Math.min(qty * 8000000, 8000000);
      }

      const tx = isSFort
        ? await shopContract.buyPacksfort(mintTypeId, qty, {
            gasLimit: ethers.utils.hexlify(gasLimit),
            gasPrice: adjustedGasPrice,
          })
        : await shopContract.buyPack(mintTypeId, qty, {
            value: amount,
            gasLimit: ethers.utils.hexlify(gasLimit),
            gasPrice: adjustedGasPrice,
          });

      const receipt: ContractReceipt = await tx.wait();

      const transferEvents = receipt.logs
        .map((log: ethers.providers.Log) => {
          try {
            return cardContract?.interface.parseLog(log); // Parse the log
          } catch (error) {
            return null; // Return null if the log cannot be parsed
          }
        })
        .filter(
          (event: any) =>
            event !== null &&
            event.name === "Transfer" &&
            event.args.from === ethers.constants.AddressZero
        );

      const tokenIds: number[] = transferEvents.map((event: any) =>
        event.args.tokenId.toNumber()
      );

      const cardImages = await Promise.all(
        tokenIds.map(async (tokenId: number) => {
          const cardData = await cardContract?.cards(tokenId);
          const categoryId = cardData.category.toNumber();
          return `/cardsvgs/${categoryId}.svg`; // Path to the card image
        })
      );

      setmintedCards(cardImages);
      console.log(tokenIds);
      console.log(cardImages);

      notify("Cards Minted Successfully!", "success");
      setCardMinted(true);
      //window.location.reload();
    } catch (e) {
      console.error("Error during mint", e);
      notify("Error during mint", "error");
    } finally {
      setMainLoader(false);
    }
  };

  const onApprove = async () => {
    if (!shopContract || !sfortContract) return;
    try {
      const amountToApprove = ethers.utils.parseUnits(approveAmt);

      setMainLoader(true);
      const tx = await sfortContract.approve(
        shopContract.address,
        amountToApprove
      );
      await tx.wait();
      notify("Allowance added successfully!", "success");
      fetchAllowancesAndBalances();
    } catch (e) {
      console.error("Error approving allowance", e);
      notify("Error approving allowance", "error");
    } finally {
      setMainLoader(false);
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isConnected && myAddress) {
      fetchCardCounts();
      fetchPrices();
      fetchAllowancesAndBalances();
    }
  }, [
    isConnected,
    myAddress,
    fetchCardCounts,
    fetchPrices,
    fetchAllowancesAndBalances,
  ]);

  const onCloseAllowanceModal = () => {
    setIsOpen(false);
  };

  const formatPriceDisplay = (price: number, qty: number): string => {
    return (price * qty).toFixed(0); // Display with two decimal places
  };

  // Function to fetch and calculate rarity percentages
  const fetchRarityPercentages = async () => {
    const totalRarityCounts = { rarity0: 0, rarity1: 0, rarity2: 0 };
    let totalCards = 0;
    const balance = await cardContract?.balanceOf(myAddress); // Get the total balance of cards in the wallet

    const batches = Math.ceil(balance / 40); // Calculate how many batches of 40 cards to process
    console.log("batches", batches);
    // Loop through the batches and count rarities
    for (let i = 0; i < batches; i++) {
      const from = i * 40;
      const cardBatch = await grabberContract?.getCardsBatch(myAddress, from);
      console.log("cardBatch", cardBatch);
      const categoryIds = cardBatch[1]; // Assume the second array is the categoryIDs
      totalCards += categoryIds.length;

      // Loop through the categoryIds and fetch metadata for rarity
      for (const categoryId of categoryIds) {
        const metadata = cardMetadata.find(
          (meta: any) => meta.id === Number(categoryId)
        );

        if (metadata) {
          const rarity = metadata.rarity;

          // Increment the count for each rarity
          if (rarity === 0) {
            totalRarityCounts.rarity0++;
          } else if (rarity === 1) {
            totalRarityCounts.rarity1++;
          } else if (rarity === 2) {
            totalRarityCounts.rarity2++;
          }
        }
      }
    }

    setRarityTotalNumber(totalRarityCounts);
    // Calculate percentages
    const rarity0Percent = (totalRarityCounts.rarity0 / totalCards) * 100;
    const rarity1Percent = (totalRarityCounts.rarity1 / totalCards) * 100;
    const rarity2Percent = (totalRarityCounts.rarity2 / totalCards) * 100;

    // Calculate total number of cards
    const total =
      totalRarityCounts.rarity0 +
      totalRarityCounts.rarity1 +
      totalRarityCounts.rarity2;
    // Calculate minimum and maximum thresholds for rarity0 and rarity2 percentages
    const minSquaresPercentage = 33.333; // minimum 33%
    const maxCirclesPercentage = 33.333; // maximum less than 33%
    // Calculate the number of cards based on percentages
    const minSquaresCards = Math.ceil((minSquaresPercentage / 100) * total); // minimum 33% of total
    const maxCirclesCards = Math.floor((maxCirclesPercentage / 100) * total); // less than 33% of total

    // Calculate max allowed for rarity0 based on remaining cards after minRarity1 and maxRarity2
    const minTrianglesCards = Math.ceil((10 / 100) * total); // minimum 10% of total for rarity1
    // const minRarity2Cards = Math.ceil((10 / 100) * total); // minimum 10% of total for rarity2
    // const maxRarity0Cards = total - minRarity1Cards - minRarity2Cards;
    // Ensure rarity0 does not exceed 80% of total
    // const maxAllowedRarity0 = Math.floor((80 / 100) * total);
    // const finalMaxRarity0Cards = Math.min(maxRarity0Cards, maxAllowedRarity0);
    setTotalRarity({
      squares: displayRarities(
        "squares",
        totalRarityCounts.rarity0,
        minSquaresCards,
        total
      ),
      triangles: displayRarities(
        "triangles",
        totalRarityCounts.rarity1,
        minTrianglesCards,
        total
      ),
      circles: displayRarities(
        "circles",
        totalRarityCounts.rarity2,
        maxCirclesCards,
        total
      ),
    });
    setRarityPercentages({
      rarity0: parseFloat(rarity0Percent.toFixed(2)),
      rarity1: parseFloat(rarity1Percent.toFixed(2)),
      rarity2: parseFloat(rarity2Percent.toFixed(2)),
    });
  };

  function displayRarities(
    rarity: any,
    value: any,
    minRarity: any,
    total: any
  ) {
    if (rarity === "squares") {
      return {
        total: value,
        min: minRarity,
      };
    }
    if (rarity === "circles") {
      return {
        total: value,
        max: minRarity,
      };
    }
    return { total: value };
  }
  console.log("totalRarity", totalRarity);

  const checkCircleMaxCardsNumber = (cards: any) => {
    return cards?.circles?.total > cards?.circles?.max ? "error" : "";
  };
  const checkCircleMinCardsNumber = (cards: any) => {
    return cards?.squares?.total < cards?.squares?.min ? "error" : "";
  };
  return (
    <div className="cardShopContainer">
      <Title title="CARD SHOP" />
      <div className="cardShopDescription">
        <p>
          To play PiCO you need a minimum of 27 cards with at least 1 character,
          1 weapon, and 1 shield.
        </p>
        <p>
          You own <b>{characterCount}</b> characters, <b>{weaponCount}</b>{" "}
          weapons, and <b>{shieldCount}</b> shields. Of which{" "}
          <span className={`${checkCircleMinCardsNumber(totalRarity)}`}>
            {rarityTotalNumber?.rarity0} / {totalRarity?.squares.min} squares,
          </span>{" "}
          <span>{rarityTotalNumber?.rarity1}</span> triangles,{" "}
          <span className={`${checkCircleMaxCardsNumber(totalRarity)}`}>
            {totalRarity?.circles?.total} / {totalRarity?.circles.max} circles
          </span>
        </p>
      </div>
      <div className="cardsList">
        <div className="cardBox">
          <img src={ShieldPackIcon} alt="Shield" />
          <div className="qtyBox">
            <PrimaryButton
              width="36px"
              text="-"
              onClick={() => onDecrease(2)}
              className="left"
            />
            <div className="value">{getQty("Shield pack (9 Cards)")?.qty}</div>
            <PrimaryButton
              width="36px"
              text="+"
              onClick={() => onIncrease(2)}
              className="right"
            />
          </div>
          <div className="buyButtons flex gap-1 grid-cols-2">
            <PrimaryButton
              onClick={() =>
                onMint(getQty("Shield pack (9 Cards)")?.qty || 1, 2, true)
              }
            >
              <img src={SingleIcon} alt="Buy" />
              <span>
                {formatPriceDisplay(
                  getQty("Shield pack (9 Cards)")?.price || 0,
                  getQty("Shield pack (9 Cards)")?.qty || 1
                )}
              </span>
            </PrimaryButton>
            <PrimaryButton
              onClick={() =>
                onMint(getQty("Shield pack (9 Cards)")?.qty || 1, 2, false)
              }
            >
              <img src={MultipleIcon} alt="Icon" />
              <span>
                {formatPriceDisplay(
                  getQty("Shield pack (9 Cards)")?.sgbprice || 0,
                  getQty("Shield pack (9 Cards)")?.qty || 1
                )}
              </span>
            </PrimaryButton>
          </div>
        </div>{" "}
        {/* End of Card Box */}
        <div className="cardBox">
          <img src={WeaponPackIcon} alt="Weapon" />
          <div className="qtyBox">
            <PrimaryButton
              width="36px"
              text="-"
              onClick={() => onDecrease(1)}
              className="left"
            />
            <div className="value">{getQty("Weapon pack (9 Cards)")?.qty}</div>
            <PrimaryButton
              width="36px"
              text="+"
              onClick={() => onIncrease(1)}
              className="right"
            />
          </div>
          <div className="buyButtons flex gap-1 grid-cols-2">
            <PrimaryButton
              onClick={() =>
                onMint(getQty("Weapon pack (9 Cards)")?.qty || 1, 1, true)
              }
            >
              <img src={SingleIcon} alt="Buy" />
              <span>
                {formatPriceDisplay(
                  getQty("Weapon pack (9 Cards)")?.price || 0,
                  getQty("Weapon pack (9 Cards)")?.qty || 1
                )}
              </span>
            </PrimaryButton>
            <PrimaryButton
              onClick={() =>
                onMint(getQty("Weapon pack (9 Cards)")?.qty || 1, 1, false)
              }
            >
              <img src={MultipleIcon} alt="Icon" />
              <span>
                {formatPriceDisplay(
                  getQty("Weapon pack (9 Cards)")?.sgbprice || 0,
                  getQty("Weapon pack (9 Cards)")?.qty || 1
                )}
              </span>
            </PrimaryButton>
          </div>
        </div>{" "}
        {/* End of Card Box */}
        <div className="cardBox">
          <img src={CharacterPackIcon} alt="Character" />
          <div className="qtyBox">
            <PrimaryButton
              width="36px"
              text="-"
              onClick={() => onDecrease(0)}
              className="left"
            />
            <div className="value">
              {getQty("Character pack (9 Cards)")?.qty}
            </div>
            <PrimaryButton
              width="36px"
              text="+"
              onClick={() => onIncrease(0)}
              className="right"
            />
          </div>
          <div className="buyButtons flex gap-1 grid-cols-2">
            <PrimaryButton
              onClick={() =>
                onMint(getQty("Character pack (9 Cards)")?.qty || 1, 0, true)
              }
            >
              <img src={SingleIcon} alt="Buy" />
              <span>
                {formatPriceDisplay(
                  getQty("Character pack (9 Cards)")?.price || 0,
                  getQty("Character pack (9 Cards)")?.qty || 1
                )}
              </span>
            </PrimaryButton>
            <PrimaryButton
              onClick={() =>
                onMint(getQty("Character pack (9 Cards)")?.qty || 1, 0, false)
              }
            >
              <img src={MultipleIcon} alt="Icon" />
              <span>
                {formatPriceDisplay(
                  getQty("Character pack (9 Cards)")?.sgbprice || 0,
                  getQty("Character pack (9 Cards)")?.qty || 1
                )}
              </span>
            </PrimaryButton>
          </div>
        </div>{" "}
        {/* End of Card Box */}
        <div className="cardBox">
          <img src={Full18CardIcon} alt="Full Pack" />
          <div className="qtyBox">
            <PrimaryButton
              width="36px"
              text="-"
              onClick={() => onDecrease(100)}
              className="left"
            />
            <div className="value">{getQty("Full Deck (27 Cards)")?.qty}</div>
            <PrimaryButton
              width="36px"
              text="+"
              onClick={() => onIncrease(100)}
              className="right"
            />
          </div>
          <div className="buyButtons flex gap-1 grid-cols-2">
            <PrimaryButton
              onClick={() =>
                onMint(getQty("Full Deck (27 Cards)")?.qty || 1, 100, true)
              }
            >
              <img src={SingleIcon} alt="Buy" />
              <span>
                {formatPriceDisplay(
                  getQty("Full Deck (27 Cards)")?.price || 0,
                  getQty("Full Deck (27 Cards)")?.qty || 1
                )}
              </span>
            </PrimaryButton>
            <PrimaryButton
              onClick={() =>
                onMint(getQty("Full Deck (27 Cards)")?.qty || 1, 100, false)
              }
            >
              <img src={MultipleIcon} alt="Icon" />
              <span>
                {formatPriceDisplay(
                  getQty("Full Deck (27 Cards)")?.sgbprice || 0,
                  getQty("Full Deck (27 Cards)")?.qty || 1
                )}
              </span>
            </PrimaryButton>
          </div>
        </div>{" "}
        {/* End of Card Box */}
      </div>
      <div className="backBtn">
        <Link to="/">
          <PrimaryButton text="Back" className="" />
        </Link>
      </div>
      <AllowancePopup
        title="Card Shop Allowance"
        balanceAllowance={Number(allowance)}
        onClickAdd={() => setIsOpen(true)}
      />
      <AllowanceModal
        open={modalIsOpen}
        onClose={onCloseAllowanceModal}
        userTokenBalance={Number(sfortBalance)}
        balance={Number(sfortBalance)}
        approveAmt={approveAmt}
        setApproveAmt={setApproveAmt}
        onApprove={onApprove}
      />

      <CardsMinted
        openModal={cardMinted}
        type={"MINTED"}
        closeModal={() => setCardMinted(false)}
        cardImages={mintedCards}
        handleYourCard={() => navigate("/my-cards")}
        primaryButtonText={"MY CARDS"}
      />

      {mainLoader && <Loader />}
    </div>
  );
};

export default CardShop;
