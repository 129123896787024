import React from "react";
import Modal from "../../Modal/Modal";
import PrimaryButton from "../../UI/PrimaryButton/PrimaryButton";
import './Congratulations.scss';

type Props = {
  openModal: boolean;
  closeModal?: () => void;
  leaderBoard?: () => void;
  number?: number;
};
const CongratulationsModal: React.FC<Props> = ({ openModal, closeModal, leaderBoard, number }) => {
  return (
      <Modal open={openModal}  onClose={() => closeModal} className="congratulations">
        <div className="modal-body">
		<div className="closeModal">x</div>
          <p className="congratulationsTitle ">
            <span className="topCornerLeftDots"></span>
            <span className="topCornerRightDots"></span>
            <span className="bottomCornerLeftDots"></span>
            <span className="bottomCorneRightDots"></span>
            CONGRATULATIONS
          </p>
		  <p className="descriptionModal">
		  You made it to <br />
		  <span className="position">#{number ?? ''}</span><br/>
		  Click the leaderboard button to check your stats.
		  </p>
		  <div className="buttons">
		  <PrimaryButton text="LEADERBOARD" onClick={() => leaderBoard} />
		  <PrimaryButton text="BACK" onClick={() => closeModal} />
		  </div>
        </div>
      </Modal>
  );
};

export default CongratulationsModal;
