export const findValidProfilePicUrl = async (address: string): Promise<string | null> => {
    const extensions = ['jpg', 'jpeg', 'png'];
    const baseUrl = "https://pico.cryptagion.com/picopics/";

    for (let i = 0; i < extensions.length; i++) {
        const url = `${baseUrl}${address}_picoprofile.${extensions[i]}`;

        try {
            // Use the Vercel API route to fetch the image
            const response = await fetch(`/api/fetchImage?imageUrl=${encodeURIComponent(url)}`);
            
            if (response.ok) {
                // If the image is found, return the local Vercel API route URL
                return `/api/fetchImage?imageUrl=${encodeURIComponent(url)}`;
            }
        } catch (error) {
            console.error(`Error fetching ${url} via API route:`, error);
        }
    }

    return null; // Return null if no valid image is found
};
