import React, { useEffect, useState } from "react";
import Modal from "../../Modal/Modal";
import PrimaryButton from "../../UI/PrimaryButton/PrimaryButton";
import BorderStyle from "../../BorderStyle/BorderStyle";
import tieIcon from "../../../../assets/images/cards/aTie.png";
import winIcon from "../../../../assets/images/cards/youWin.png";
import loseIcon from "../../../../assets/images/cards/youLose.png";
import cardsMintedIcon from "../../../../assets/images/cards/cardsMinted.png";
import cardSlideBG from "../../../../assets/images/cards/sliderBGCards.png";
import "./CardsMinted.scss";

type Props = {
  openModal: boolean;
  closeModal?: () => void;
  handleYourCard?: () => void;
  handleSecondaryButton?: () => void;
  mainButtonText?: string;
  hideMainButton?: boolean;
  primaryButtonText?: string;
  secondaryButtonsText?: string;
  type?: "WIN" | "DRAW" | "LOSE" | "MINTED";
  fort?: number;
  cards?: number;
  cardImages?: string[]; // Array of image paths
};

const CardsMinted: React.FC<Props> = ({
  openModal = false,
  closeModal,
  handleSecondaryButton,
  handleYourCard,
  primaryButtonText = "YOUR CARDS",
  secondaryButtonsText = "BACK",
  type = "DRAW",
  fort = 0,
  cards = 0,
  cardImages = [],
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fade, setFade] = useState("fade-in");

  // Only set up the interval if the modal is open and there are images
  useEffect(() => {
    if (openModal && cardImages.length > 0) {
      const interval = setInterval(() => {
        setFade("fade-out");
        setTimeout(() => {
          setCurrentImageIndex((prevIndex) =>
            prevIndex === cardImages.length - 1 ? 0 : prevIndex + 1
          );
          setFade("fade-in");
        }, 0); // Match the timeout with the fade-out transition time
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [openModal, cardImages.length]);

  const handleModalClose = () => {
    if (closeModal) {
      closeModal();
    }
  };
  // Safeguard against accessing undefined or empty image paths
  const currentImageSrc: any =
    cardImages.length > 0 ? cardImages[currentImageIndex] : "";

  // Ensure the component doesn't render if the modal is not open
  if (!openModal) return null;

  console.log("currentImageSrc", cardImages);
  return (
    <Modal
      open={openModal}
      onClose={handleModalClose}
      className={`cardsMinted ${type}`}
    >
      <div className="cardsMinted">
        <div className="closeModal" onClick={handleModalClose}>
          x
        </div>
        <div className="images">
          <div className="btns">
            <div className="box">
              {type === "WIN" ? (
                <img src={winIcon} alt="You win" />
              ) : type === "LOSE" ? (
                <img src={loseIcon} alt="You lose" />
              ) : type === "DRAW" ? (
                <img src={tieIcon} alt="A Tie" />
              ) : (
                <img src={cardsMintedIcon} alt="Cards Minted" />
              )}
            </div>
            {/* <div className="box"> */}
            {/* YOU
              <div className="whiteLine" />
              <BorderStyle /> */}
            {/* </div> */}
            {/* <div className="box">
              <BorderStyle /> {type === "WIN" ? "WIN" : type === "LOSE" ? "LOSE" : "DRAW"}
              <div className="whiteLine" />
            </div> */}
            {type === "MINTED" ? (
              <p>Take a look at all your new PiCO cards!</p>
            ) : (
              <p>
                Reward: <br />
                <span>{fort} $FORT</span> <br />
                You burned: <br />
                <span>{cards} cards</span>
              </p>
            )}
          </div>
          <div className="cardsSlide">
            <img
              src={cardSlideBG}
              alt="Card Background"
              className="cardBackground"
            />
            {currentImageSrc && (
              <img
                src={
                  currentImageSrc.imagePath
                    ? currentImageSrc.imagePath
                    : currentImageSrc
                }
                alt="Cards"
                className={`cardsBg ${fade}`}
              />
            )}
          </div>
        </div>
        <div className="actionButtons">
          <PrimaryButton text={primaryButtonText} onClick={handleYourCard} />
        </div>
      </div>
    </Modal>
  );
};

export default CardsMinted;
