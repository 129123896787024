import React, { useEffect, useMemo, useRef, useState } from "react";
import "./GameBoard.scss";
import PlayerInfo from "./Components/PlayerInfo/PlayerInfo";
import PlayersCards from "./Components/PlayersCards/PlayersCards";
import MainLogo from "../../Components/Common/MainLogo/MainLogo";
import CardsOpened from "./Components/CardOpened/CardOpened";
import { useWeb3 } from "../../web3/Web3Context";
import { pico1v1Contract, cardContract } from "../../web3/contracts";
import cardMetadata from "../../web3/cardMetadata.json";
import { waitRefresh } from "../../utils/waitRefresh";
import InfoModalWithImage from "../../Components/Common/Modals/InfoModalWithImage/InfoModalWithImage";
import InfoModal from "../../Components/Common/Modals/InfoModal/InfoModal";
import { useNavigate } from "react-router-dom";

import Image1 from "../../assets/images/cards/cardBg.png";
import Loader from "../../Components/Common/Loader/Loader";
import CardsMinted from "../../Components/Common/Modals/CardsMinted/CardsMinted";

import slideImage1 from "../../assets/images/cards/346.svg";
import slideImage2 from "../../assets/images/cards/351.svg";
import slideImage3 from "../../assets/images/cards/7.svg";

const GameBoard = () => {
  const { myAddress } = useWeb3();
  const [time, setTime] = useState<number>(0);
  const [active, setActive] = useState<boolean>(false);
  const [mutagenIsOpen, setMutagenIsOpen] = useState(false);

  const [roomDetail, setRoomDetail] = useState<any>(null);
  const [isCreator, setIsCreator] = useState<boolean>(false);
  const [isMyTurn, setIsMyTurn] = useState<boolean>(false);
  const [isGameover, setIsGameover] = useState<boolean>(false);
  const [isForcePlayer, setIsForcePlayer] = useState<boolean>(false);
  const [mainLoader, setMainLoader] = useState<boolean>(true);

  const [playedCards, setPlayedCards] = useState<any[]>([]);
  const [cardTypes, setCardTypes] = useState<number[]>([]);
  const [myCards, setMyCards] = useState<
    { tokenId: number; categoryId: number; type: number; isMutant: boolean }[]
  >([]);
  const [roomID, setRoomID] = useState<number | null>(null);
  const [comboSize, setComboSize] = useState<any>(0);
  const [mutagenCard, setMutagenCard] = useState<{
    tokenId: number;
    categoryId: number;
  } | null>(null);

  const [playedTypes, setPlayedTypes] = useState<number[]>([]);
  const [currentTurn, setCurrentTurn] = useState<number>(-1);
  const [mutagenImage, setMutagenImage] = useState<string>(Image1);
  const [hasMutagen, setHasMutagen] = useState<boolean>(false);
  const [winnerDetails, setWinnerDetails] = useState<any>(undefined);
  const cardsOpenedRef = useRef<HTMLDivElement>(null);
  const [sliderCards, setSliderCards] = useState<any>(undefined);
  const [burnedCards, setBurnedCards] = useState<any>(undefined);
  const [burnedCardsList, setBurnedCardsList] = useState<any>(undefined);
  const [winnerId, setWinnerId] = useState<any>(undefined);
  const [mutagendConfirmationModal, setMutagendConfirmationModal] =
    useState<boolean>(false);
  let mutagendConfirmationModal2 = false;
  const navigate = useNavigate();

  const cardImages: any = [slideImage1, slideImage2, slideImage3];

  useEffect(() => {
    const fetchRoomId = async () => {
      try {
        const roomId = await pico1v1Contract
          ?.users(myAddress)
          .then((user: any) => user?.currentroom);
        setRoomID(roomId);
      } catch (error) {
        console.error("Error fetching room ID:", error);
      }
    };

    fetchRoomId();
  }, [myAddress]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setMainLoader(false); // Stop loading after 3 seconds
    }, 2000);

    // Cleanup the timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  const fetchRoomDetails = async () => {
    if (!pico1v1Contract || !myAddress) {
      console.error("pico1v1Contract or myAddress is not available.");
      return;
    }

    try {
      /*
      const roomId = await pico1v1Contract?.users(myAddress).then((user: any) => user?.currentroom);
      if (roomId === null || roomId === undefined) {
        //setRoomID(roomId);
      }
      */

      if (roomID && roomID !== 0) {
        const roomData = await pico1v1Contract?.rooms(roomID);
        setRoomDetail(roomData);

        // Determine if the current user is the creator
        const isUserCreator =
          roomData?.creator?.toLowerCase() === myAddress?.toLowerCase();
        setIsCreator(isUserCreator);

        // Set the current turn
        setCurrentTurn(roomData.turn);

        // Determine if it's the current user's turn
        const isTurn =
          (isUserCreator && roomData.turn % 2 === 0) ||
          (!isUserCreator && roomData.turn % 2 !== 0);
        setIsMyTurn(isTurn);

        const playedCardsArray = await pico1v1Contract?.getPlayedCards(roomID);
        const firstSixCards = playedCardsArray.slice(0, 6);

        const fetchedCards = await Promise.all(
          firstSixCards.map(async (tokenId: any, index: number) => {
            if (tokenId.isZero()) {
              return null;
            }

            const tokenIdNumber = tokenId.toNumber();
            const cardData = await cardContract?.cards(tokenIdNumber);
            const metadata = cardMetadata.find(
              (meta: any) => meta.id === cardData?.category?.toNumber()
            );

            const isOpponent = isUserCreator
              ? index % 2 !== 0
              : index % 2 === 0;
            const isMutant = cardData.state > 100;

            return {
              tokenId: tokenIdNumber,
              catId: cardData?.category?.toNumber(),
              type: metadata?.type,
              isOpponent,
              isMutant,
            };
          })
        );

        const validCards = fetchedCards.filter(Boolean);

        setPlayedCards(validCards);
        setCardTypes(validCards.map((card) => card?.type));

        const myPlayedTypes = validCards
          .filter((card) => !card.isOpponent)
          .map((card) => card?.type);

        setPlayedTypes(myPlayedTypes);

        const [tokenIds, categoryIds] = await pico1v1Contract?.getMyCards();

        const cardsData = await Promise.all(
          tokenIds.map(async (tokenId: any, index: number) => {
            const categoryId = categoryIds[index].toNumber();
            const cardData = await cardContract?.cards(tokenId.toNumber());
            const metadata = cardMetadata.find(
              (meta: any) => meta.id === categoryId
            );
            const isMutant = cardData.state > 100;

            return {
              tokenId: tokenId.toNumber(),
              categoryId,
              type: metadata?.type,
              isMutant, // Assign isMutant property based on cardData.state
            };
          })
        );

        setMyCards(cardsData);

        if ((roomData.turn === 6 || roomData.turn === 7) && isTurn) {
          console.log("LAST CARD PLAYED before 4 second");
          setTimeout(() => {
            console.log("CALL AFTER 4 SECOND");
            console.log("START TIMER LAST ROUND");
            const mutagen = cardsData.find((card) => card.type === 3);
            if (mutagen) {
              setMutagenCard(mutagen);
            } else {
              setMutagenCard(null); // No mutagen, so set null
            }
            console.log("MUTAGEN MODAL OPEN", mutagendConfirmationModal);

            // Call mutagen modal only once
            if (!mutagendConfirmationModal2) {
              console.log("INSIDE MUTAGEN MDOAL");
              mutagendConfirmationModal2 = true;
              setMutagendConfirmationModal(true);
              setMutagenIsOpen(true);
            }
          }, 4000);
        } else {
          setMutagenIsOpen(false);
        }
      } else {
        console.error("No active room found for the user.");
      }
    } catch (error) {
      console.error("Error fetching room details:", error);
    }
  };

  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    let isFetching = false;
    const fetchRoomTurn = async () => {
      if (roomID !== null && !isFetching) {
        isFetching = true; // Set fetching flag to true to prevent overlapping fetches

        try {
          const roomData = await pico1v1Contract?.rooms(roomID);
          if (roomData.turn !== currentTurn) {
            setCurrentTurn(roomData.turn);
            await fetchRoomDetails(); // Ensure fetchRoomDetails is defined in the correct scope
          }

          //SET Time to real time from chain
          const currentTimeInSeconds = Math.floor(Date.now() / 1000);
          const lastTurnTime = Number(roomData.lastaction);
          const timeDifferenceInSeconds = currentTimeInSeconds - lastTurnTime;
          let remainingTimeInSeconds = 100 - timeDifferenceInSeconds;
          remainingTimeInSeconds =
            remainingTimeInSeconds > 0 ? remainingTimeInSeconds : 0;

          setTime(Number(remainingTimeInSeconds) * 1000);

          // Check if the game is over
          if (roomData.turn > 7 && !isGameover) {
            setMutagenIsOpen(false);
            setIsForcePlayer(false);

            //step 0:
            // console.log("START");
            // await delay(10000); // 10 seconds delay while animations play
            // console.log("END");
            //check if either used mutagen used and play mutagen animation

            //await play animation

            // Step 1: Fetch burnt cards
            const burntCards = await pico1v1Contract?.getBurntCards(roomID);
            // Step 2: Convert burntCards to numbers and filter non-zero token IDs
            const validBurntCards = burntCards
              .map((cardId: any) => cardId.toNumber())
              .filter((cardId: number) => cardId !== 0);

            // Step 3: Count valid burnt cards
            const cardsCount = validBurntCards.length;

            // Step 4: Fetch CatIDs and generate image paths
            const cardImagesWithCategory: any = await Promise.all(
              validBurntCards.map(async (tokenId: number) => {
                const cardData = await cardContract?.cards(tokenId);
                const categoryId = cardData.category.toNumber();
                const isMutant = cardData.state > 100;
                const imagePath = isMutant
                  ? `/mutantsvgs/${categoryId}.svg`
                  : `/cardsvgs/${categoryId}.svg`;

                return {
                  tokenId,
                  categoryId,
                  imagePath,
                };
              })
            );
            // step 5: play animations to burn all cards
            setBurnedCardsList(cardImagesWithCategory);
            await delay(10000);

            // loop for all validburncards... play burn animation on cards and delay X seconds
            //per card

            // Remove any null values from the cardImages array, just in case
            const filteredCardImages = cardImagesWithCategory.filter(
              (image: any) => image.imagePath !== null
            );

            setSliderCards(filteredCardImages);
            setBurnedCards(cardsCount);
            console.log("Cards Count:", cardsCount);
            console.log("Card Images:", filteredCardImages);

            // Step 4: Determine the game outcome
            setRoomDetail(roomData);
            setWinnerId(roomData?.turn);
            setIsGameover(true);
            setMutagenIsOpen(false);
            setIsForcePlayer(false);
            clearInterval(intervalId);
            isFetching = false;
          }
        } catch (error) {
          console.error("Error fetching room turn:", error);
        } finally {
          isFetching = false; // Reset the fetching flag after the async work is done
        }
      }
    };

    const intervalId = setInterval(fetchRoomTurn, 3000);

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, [roomID]);

  useEffect(() => {
    const isPlayerCreator = roomDetail?.creator === myAddress;
    if (roomDetail?.turn === 150) {
      setWinnerDetails({
        type: "DRAW",
        fort: Number(roomDetail.bet * 0.95),
        cards: burnedCards,
        cardImages: sliderCards,
      });
    } else if (roomDetail?.turn === 100) {
      if (isPlayerCreator) {
        setWinnerDetails({
          type: "WIN",
          fort: Number(roomDetail.bet * 2 * 0.95),
          cards: burnedCards,
          cardImages: sliderCards,
        });
      } else {
        setWinnerDetails({
          type: "LOSE",
          fort: 0,
          cards: burnedCards,
          cardImages: sliderCards,
        });
      }
    } else if (roomDetail?.turn === 200) {
      if (!isPlayerCreator) {
        setWinnerDetails({
          type: "WIN",
          fort: Number(roomDetail.bet * 2 * 0.95),
          cards: burnedCards,
          cardImages: sliderCards,
        });
      } else {
        setWinnerDetails({
          type: "LOSE",
          fort: 0,
          cards: burnedCards,
          cardImages: sliderCards,
        });
      }
    }
  }, [burnedCards, sliderCards, winnerId, currentTurn]);

  useEffect(() => {
    if (Number(time) === 0 && !isMyTurn) {
      if (!isGameover) {
        setIsForcePlayer(true);
      }
    } else {
      setIsForcePlayer(false);
    }
  }, [isMyTurn, time]);

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (
        cardsOpenedRef.current &&
        !cardsOpenedRef.current.contains(event.target as Node)
      ) {
        setActive(false);
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (
        cardsOpenedRef.current &&
        !cardsOpenedRef.current.contains(event.target as Node)
      ) {
        setActive(false);
      }
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onClickCard = async (card: any) => {
    setMainLoader(true);
    if (roomID !== null && card) {
      try {
        const tx = await pico1v1Contract?.playCard(roomID, card);
        await tx.wait();
        setMainLoader(false);

        setPlayedTypes([...playedTypes, card.type]);
      } catch (error) {
        console.error("Error playing card:", error);
      } finally {
        setMainLoader(false);
      }
    } else {
      console.error("Invalid roomID or card");
    }

    setActive(false);
  };

  const getCardSlot = (type: number) => {
    switch (type) {
      case 0:
        return 1; // Characters should go into slot 1
      case 1:
        return 0; // Weapons should go into slot 0
      case 2:
      default:
        return 2; // Shields should remain in slot 2
    }
  };

  const handleGameOverReturnClick = () => {
    navigate("/");
  };

  const handlePlayMutagen = async () => {
    if (mutagenCard) {
      try {
        const tx = await pico1v1Contract?.playCard(
          roomID,
          Number(mutagenCard.tokenId)
        );
        await tx.wait();
        console.log("Mutagen played successfully.");
      } catch (error) {
        console.error("Error playing mutagen:", error);
      }
    }
    setMutagenIsOpen(false);
  };

  const handleForceOpponent = async () => {
    try {
      const tx = await pico1v1Contract?.forceOpponentPlay(roomID);
      await tx.wait();
      console.log("Forces Opponent Hand");
    } catch (error) {
      console.error("Error Forcing Opponent Hand:", error);
    }
    setIsForcePlayer(false);
  };

  const handlePass = async () => {
    setMainLoader(true);
    try {
      const tx = await pico1v1Contract?.playCard(roomID, 0); // Passing 0 as the cardID
      await tx.wait();
      console.log("Turn passed successfully.");
    } catch (error) {
      console.error("Error passing turn:", error);
    } finally {
      setMainLoader(false);
    }
    setMutagenIsOpen(false);
  };

  const mutantStatuses = useMemo(
    () => playedCards.map((card) => card.isMutant),
    [playedCards]
  );

  return (
    <>
      <div className="gameBoardContainer">
        <MainLogo isTextAvailable={false} />
        <div className="gameBoard">
          {roomDetail && (
            <>
              <PlayerInfo
                total={3}
                playerAddress={
                  isCreator ? roomDetail?.joiner : roomDetail?.creator
                }
                isTurn={!isMyTurn}
                miliseconds={time}
              />
              <PlayersCards
                playedCards={playedCards.map((card) => ({
                  ...card,
                  slot: getCardSlot(card.type), // Use the corrected slot mapping
                }))}
                cardTypes={cardTypes}
                mutantStatuses={mutantStatuses} // Pass mutantStatuses prop
                isCreator={isCreator}
                isForcePlayer={isForcePlayer}
                forceOpponentHand={handleForceOpponent}
                setComboSize={(size: any) => setComboSize(size)}
                comboSize={comboSize}
                burnedCardsList={burnedCardsList}
              />
              <PlayerInfo
                miliseconds={time}
                total={3}
                playerAddress={myAddress}
                isTurn={isMyTurn}
              />
            </>
          )}
        </div>
      </div>

      <CardsOpened
        ref={cardsOpenedRef}
        isMyTurn={isMyTurn}
        onClickCard={onClickCard}
        active={active}
        handleActive={() => setActive(!active)}
        cards={myCards.map((card) => ({
          ...card,
          disabled:
            playedTypes.includes(card.type) &&
            !(card.type === 3 && currentTurn >= 6),
        }))}
      />

      <InfoModalWithImage
        openModal={mutagenIsOpen}
        description={
          mutagenCard
            ? "This is the mutagen round, play or pass!?"
            : "You have no mutagen, Pass the turn"
        }
        img={mutagenCard ? `/cardsvgs/${mutagenCard.categoryId}.svg` : Image1}
        mainButtonText="PLAY MUTAGEN" // Text for the main button
        hideMainButton={!mutagenCard} // Hide the main button if no mutagenCard is available
        secondButtonText="PASS"
        closeModal={() => setMutagenIsOpen(false)}
        handleBack={handlePass} // Handle the "PASS" button click
        handleMainButton={handlePlayMutagen} // Handle the "PLAY MUTAGEN" button click
      />

      {/* <InfoModal
        openModal={!!(isGameover && roomID)}
        title="Game Over"
        description="The game has ended. "
        closeModal={handleGameOverReturnClick}
        handleMainButton={handleGameOverReturnClick}
        mainButtonText="Home"
        secondButtonText="Home"
        handleBack={handleGameOverReturnClick}
        hideMainButton={true}
        hideSecondButton={false}
      /> */}
      <CardsMinted
        openModal={!!(isGameover && roomID)}
        type={winnerDetails?.type}
        fort={winnerDetails?.fort}
        cards={winnerDetails?.cards}
        cardImages={winnerDetails?.cardImages}
        handleYourCard={handleGameOverReturnClick}
        primaryButtonText={"HOME"}
      />

      {mainLoader && <Loader comboSize={comboSize} />}
    </>
  );
};

export default GameBoard;
