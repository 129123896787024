import React, { useEffect, useState } from "react";
import { pico1v1Contract } from "../../web3/contracts";
import "./YourTrophies.scss";
import BorderCorners from "../../Components/Common/BorderCorners/BorderCorners";
import LeftRightArrow from "../../Components/SVGComponents/LeftRightArrow";
import YourTrophiesList from "../../Components/YourTrophiesList/YourTrophiesList";
import { useWeb3 } from "../../web3/Web3Context";
import { ethers } from "ethers";

const YourTrophies = () => {
  const { myAddress, isConnected } = useWeb3();
  const [yourTrophiesData, setYourTrophiesData] = useState<any>(null);
  const [tournamentPlacements, setTournamentPlacements] = useState<any[]>([]);

  useEffect(() => {
    const fetchTrophiesData = async () => {
      if (!pico1v1Contract || !myAddress) {
        console.error("Contract or address is undefined");
        return;
      }

      try {
        console.log("Fetching data for address:", myAddress);
        const userData = await pico1v1Contract.users(myAddress);
        console.log("Data fetched from contract:", userData);

        const sfortWonParsed = Math.floor(
          Number(ethers.utils.formatEther(userData.sfortwon))
        );

        setYourTrophiesData({
          currentRoom: userData.currentroom.toString(),
          gamesWon: userData.gameswon.toString(),
          gamesPlayed: userData.gamesplayed.toString(),
          sfortWon: sfortWonParsed.toString(),
          cardsBurnt: userData.cardsburnt.toString(),
          cardsLost: userData.cardslost.toString(),
        });
      } catch (error) {
        console.error("Error fetching trophies data:", error);
      }
    };

    const fetchTournamentData = async () => {
      if (!pico1v1Contract || !myAddress) return;

      try {
        // Get the current tournament number
        const currentTournament = await pico1v1Contract.tournamentNumber();
        const currentTournamentNumber = currentTournament.toNumber();
        const tournamentsToFetch = Math.min(currentTournamentNumber, 5); // Fetch up to 5 previous tournaments

        const placementPromises = [];
        for (let i = 0; i < tournamentsToFetch; i++) {
          const tournamentNumber = currentTournamentNumber - 1 - i;
          placementPromises.push(fetchTournamentPlacement(tournamentNumber));
        }

        const placements = await Promise.all(placementPromises);
        setTournamentPlacements(placements.filter(Boolean)); // Filter out null responses
      } catch (error) {
        console.error("Error fetching tournament data:", error);
      }
    };

    const fetchTournamentPlacement = async (tournamentNumber: number) => {
      try {
        const { winners, prizes } = await pico1v1Contract?.getTournamentWinners(
          tournamentNumber
        );
        const winnerIndex = winners.findIndex(
          (winner: string) => winner.toLowerCase() === myAddress?.toLowerCase()
        );

        if (winnerIndex !== -1) {
          // User placed in the tournament
          const prize = ethers.utils.formatEther(prizes[winnerIndex]);
          return `Tournament #${tournamentNumber} - Placed ${
            winnerIndex + 1
          } - won ${prize} $FORT`;
        } else {
          // User didn't place in the top 10
          return `Tournament #${tournamentNumber} - Not Top 10`;
        }
      } catch (error) {
        console.error(
          `Error fetching tournament #${tournamentNumber} data:`,
          error
        );
        return null;
      }
    };

    if (isConnected && myAddress) {
      fetchTrophiesData();
      fetchTournamentData();
    }
  }, [isConnected, myAddress]);

  return (
    <div className="yourTrophies">
      <BorderCorners />
      <div className="header">
        <div className="leftArrow"></div>
        <div className="title">
          <p>
            MY CAREER
            <span className="leaderBoardSubtitle">Pixel Chain Olympus</span>
          </p>
        </div>
        <div className="rightArrow"></div>
      </div>{" "}
      {/* end of header */}
      <div className="tournamentPlacements">
        {yourTrophiesData ? (
          <>
            <YourTrophiesList data={yourTrophiesData} />
            <div className="tournamentPlacements trophies">
              <div className="title">
                <p>Tournament Results</p>
              </div>
              {tournamentPlacements.length > 0 ? (
                <div className="trophy-item">
                  <span className="trophy-label">
                    {" "}
                    {tournamentPlacements.map((placement, index) => (
                      <li key={index}>{placement}</li>
                    ))}
                  </span>
                </div>
              ) : (
                <p>No tournament placements to display</p>
              )}
            </div>
          </>
        ) : (
          <h1 className="text-center pt-4 pb-10 text-xl">
            You don't have any trophies... Yet.
          </h1>
        )}
      </div>
    </div>
  );
};

export default YourTrophies;
