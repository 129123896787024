import React, { useState } from "react";

import "./DeckBuilder.scss";
import Title from "../../Components/Common/Title/Title";
import ProgressDemo from "../../Components/Common/ProgressBar/ProgressBar";
import character from "../../assets/images/pico-card-collection/character-icon.png";
import weapon from "../../assets/images/pico-card-collection/weapon-icon.png";
import shield from "../../assets/images/pico-card-collection/shield-icon.png";
import ToggleSwitch from "../../Components/Common/UI/ToggleSwitch/ToggleSwitch";
import { deckBuilderCards } from "../../data/deckBuilder";
import PrimaryButton from "../../Components/Common/UI/PrimaryButton/PrimaryButton";
import { Link } from "react-router-dom";
import icon1_1 from "../../assets/images/deckBuilder/icon1_1.png";
import icon1_2 from "../../assets/images/deckBuilder/icon1_2.png";
import icon1_3 from "../../assets/images/deckBuilder/icon1_3.png";
import icon1_1h from "../../assets/images/deckBuilder/icon1_1h.png";
import icon1_2h from "../../assets/images/deckBuilder/icon1_2h.png";
import icon1_3h from "../../assets/images/deckBuilder/icon1_2h.png";

import icon2_1 from "../../assets/images/deckBuilder/icon2_1.png";
import icon2_2 from "../../assets/images/deckBuilder/icon2_2.png";
import icon2_3 from "../../assets/images/deckBuilder/icon2_3.png";

import icon3_1 from "../../assets/images/deckBuilder/icon3_1.png";
import icon3_2 from "../../assets/images/deckBuilder/icon3_2.png";
import icon3_3 from "../../assets/images/deckBuilder/icon3_3.png";

interface Card {
  id: number; // Assume each card has a unique `id` field
  image: string;
  cardType: number;
  name: string;
  rarity: number;
  cattype: number;
  strength: number;
}

const DeckBuilder: React.FC = () => {
  const [isApprovedForAll, setIsApprovedForAll] = useState<boolean>(false);
  const [selectedCards, setSelectedCards] = useState<Card[]>([]);

  const handleToggleChange = async (checked: boolean) => {
    setIsApprovedForAll(checked);
  };

  const toggleCardSelection = (card: Card) => {
    setSelectedCards((prevSelected) => {
      if (prevSelected.some((selectedCard) => selectedCard.id === card.id)) {
        // If card is already selected, remove it
        return prevSelected.filter(
          (selectedCard) => selectedCard.id !== card.id
        );
      } else {
        // Otherwise, add it to the selected array
        return [...prevSelected, card];
      }
    });
  };
  return (
    <div className="deckBuilder">
      <div className="container">
        <Title title="DECK BUILDER" />
        <p>
          On this page you can build a play deck. When you are happy with the
          deck you build you can bulk send all other cards to your very own card
          storage. Whenever you need your stored cards back you can safely
          return them to your deck.
        </p>
        <div className="progress">
          <label>Tier and Requirments</label>
          <ProgressDemo max={500} currentValue={100} />
        </div>
        <div className="cardIconsInfo">
          <ul>
            <li>
              <img src={icon1_1} alt="Icon" />
            </li>
            <li>
              <img src={icon1_2} alt="Icon" />
            </li>
            <li>
              <img src={icon1_3} alt="Icon" />
            </li>
          </ul>
          <ul>
            <li>
              <img src={icon2_1} alt="Icon" />
            </li>
            <li>
              <img src={icon2_2} alt="Icon" />
            </li>
            <li>
              <img src={icon2_3} alt="Icon" />
            </li>
          </ul>
          <ul>
            <li>
              <img src={icon3_1} alt="Icon" />
            </li>
            <li>
              <img src={icon3_2} alt="Icon" />
            </li>
            <li>
              <img src={icon3_3} alt="Icon" />
            </li>
          </ul>
        </div>
        <div className="cardInfo">
          <p>
            {selectedCards?.length}/{deckBuilderCards?.length} cards {"<"} 15%
            doubles
          </p>
          <ul>
            <li>
              <span>0/6</span>
              <img src={character} alt="Square" />
            </li>
            <li>
              <span>0/6</span>
              <img src={weapon} alt="Triangle" />
            </li>
            <li>
              <span>0/6</span>
              <img src={shield} alt="Circle" />
            </li>
          </ul>
        </div>
        <ToggleSwitch
          checked={isApprovedForAll}
          onChange={handleToggleChange}
          title="CARD ACCESS:"
        />
        <div className="deckBuilderCards">
          {deckBuilderCards?.map((item: any, index: number) => (
            <img
              src={item.image}
              onClick={() => toggleCardSelection(item)}
              className={`transition-all card hover:-translate-y-2 ${
                selectedCards.some(
                  (selectedCard) => selectedCard.id === item.id
                )
                  ? "selected"
                  : ""
              }`}
              alt="card"
            />
          ))}
        </div>
      </div>
      <div className="deckBuilderBtns">
        <div className="btn-groups">
          <PrimaryButton text="Add To Deck" onClick={() => {}} />
          <PrimaryButton text="Store Cards" onClick={() => {}} />
          <Link to="/">
            <PrimaryButton text="Back" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DeckBuilder;
