import React, { useEffect, useState, useCallback } from "react";
import Title from "../../Components/Common/Title/Title";
import { ReactComponent as RewardBG } from "../../assets/images/mutant/rewardBg.svg";
import { ReactComponent as RewardIcon } from "../../assets/images/mutant/rewardIcon.svg";
import ToggleSwitch from "../../Components/Common/UI/ToggleSwitch/ToggleSwitch";
import mainPhotoBg from "../../assets/images/mutant/mutantbgAnim.gif";
import stake from "../../assets/images/cards/351.svg";
import "./MutantResearch.scss";
import BorderStyle from "../../Components/Common/BorderStyle/BorderStyle";
import PrimaryButton from "../../Components/Common/UI/PrimaryButton/PrimaryButton";
import { ReactComponent as MutantFooter } from "../../assets/images/mutant/mutantFooter.svg";
import { useWeb3 } from "../../web3/Web3Context";
import AllowancePopup from "../../Components/Common/AllowancePopup/AllowancePopup";
import AllowanceModal from "../../Components/Modals/AllowanceModal/AllowanceModal";
import { notify } from "../../utils/customToast";
import {
  getDay,
  getHours,
  isAfter,
  setHours,
  setMinutes,
  setSeconds,
} from "date-fns";

import {
  cardContract,
  stakingContract,
  vaultContract,
  sfortContract,
  grabberContract,
} from "../../web3/contracts";
import { ethers } from "ethers";
import InfoModal from "../../Components/Common/Modals/InfoModal/InfoModal";
import { useNavigate } from "react-router-dom";
import Loader from "../../Components/Common/Loader/Loader";

const MutantResearch = () => {
  const { myAddress } = useWeb3();
  const navigate = useNavigate();
  const [stakedMutants, setStakedMutants] = useState<any[]>([]);
  const [estimatedReward, setEstimatedReward] = useState<string>("0");
  const [totalPool, setTotalPool] = useState<string>("0");
  const [isApprovedForAll, setIsApprovedForAll] = useState<boolean>(false);
  const [totalPercent, setTotalPercent] = useState<number>(0);
  const [cardAccess, setCardAccess] = useState<boolean>(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [allowance, setAllowance] = useState<string>("0");
  const [approveAmt, setApproveAmt] = useState<string>("0");
  const [mainLoader, setMainLoader] = useState<boolean>(false);
  const [unstakedMutants, setunstakedMutants] = useState<number>(0);
  const [costStake, setcostStake] = useState<number>(0);
  const [stakedCount, setstakedCount] = useState<number>(0);
  const [stakedSFORT, setstakedSFORT] = useState<number>(0);
  const [isMutantClosed, setMutantClosed] = useState<boolean>(false);
  const [sfortBalance, setSfortBalance] = useState<string>("0");
  const [currentTier, setCurrentTier] = useState(0); // Default to Tier 0
  const [progressPercentage, setProgressPercentage] = useState(0); // Default progress is 0%

  useEffect(() => {
    setMutantClosed(isThursdayToSunday(new Date()));
  }, []);

  useEffect(() => {
    const fetchMutantStakingData = async () => {
      if (!stakingContract || !myAddress) return;

      try {
        // Fetch staked mutants
        const [count, lockedsfort, mutantTokenIds, mutantCategoryIds] =
          await stakingContract?.getStakedMutants(myAddress);
        const mutantsData = mutantTokenIds.map(
          (tokenId: number, index: number) => ({
            tokenId,
            categoryId: mutantCategoryIds[index],
            imageUrl: `mutantsvgs/${mutantCategoryIds[index]}.svg`, // Assuming this is the path for mutant images
          })
        );
        setStakedMutants(mutantsData);
        setstakedCount(Number(count));
        setstakedSFORT(Number(lockedsfort));

        // Get the current tier and progress information based on the count
        const { tier, progressPercentage } = getTierInfo(Number(count));

        // Update state with the current tier and progress information
        setCurrentTier(tier);
        setProgressPercentage(progressPercentage);

        const totalMutants = await getTotalMutants(); // Call your function to get the count
        const cost = await stakingContract?.lockAmount();

        if (totalMutants !== undefined) {
          setunstakedMutants(totalMutants); // Update the state with the total number of mutants
          setcostStake(Number(totalMutants) * Number(cost));
        }

        // Fetch estimated reward and format to nearest integer
        const reward = await stakingContract.getEstimatedReward(myAddress);
        setEstimatedReward(
          `${Math.floor(Number(ethers.utils.formatEther(reward)))} $FORT`
        );

        // Fetch total staking pool from vault contract
        const stakingPoolsFort = await vaultContract?.stakingPoolsfort();
        setTotalPool(
          `${Math.floor(
            Number(ethers.utils.formatEther(stakingPoolsFort))
          )} $FORT`
        );

        // Calculate total percent for reward level display
        setTotalPercent(count > 0 ? Math.min((count / 5) * 100, 100) : 0); // Adjust based on logic for levels
      } catch (error) {
        console.error("Error fetching staking data:", error);
      }
    };

    if (myAddress) {
      fetchMutantStakingData();
      checkApprovalForAll(); // Check card approval status
    }
  }, [myAddress]);

  // Check if the user has granted card access approval to the staking contract
  const checkApprovalForAll = useCallback(async () => {
    if (!cardContract || !stakingContract || !myAddress) return;
    try {
      const isApproved = await cardContract.isApprovedForAll(
        myAddress,
        stakingContract.address
      );
      setIsApprovedForAll(isApproved);
      setCardAccess(isApproved);
    } catch (error) {
      console.error("Error checking approval status:", error);
    }
  }, [cardContract, stakingContract, myAddress]);

  const handleStakeAll = async () => {
    if (!stakingContract) return;
    try {
      setMainLoader(true);

      /*
      const currentAllowance = ethers.utils.parseEther(allowance);
      if (currentAllowance.lt(amount)) {
        notify(
          "Insufficient allowance. Please increase your allowance.",
          "error"
        );
        setMainLoader(false);
        return;
      }
          /// NEED To find all mutant counts that exists and multiply by current cost
      */

      const tx = await stakingContract.stakeAll();
      await tx.wait();
      console.log("Staked all mutants successfully");
      window.location.reload();
    } catch (error) {
      console.error("Error staking all mutants:", error);
    }
  };

  const handleUnstakeAll = async () => {
    if (!stakingContract) return;
    try {
      setMainLoader(true);
      const tx = await stakingContract.unstakeAll();
      await tx.wait();
      console.log("Unstaked all mutants successfully");
      window.location.reload();
    } catch (error) {
      console.error("Error unstaking all mutants:", error);
    }
  };

  const toggleCardAccess = async (checked: boolean) => {
    setCardAccess(checked);
    if (!cardContract || !stakingContract || !myAddress) return;
    try {
      const tx = await cardContract.setApprovalForAll(
        stakingContract.address,
        checked
      );
      await tx.wait();
      setIsApprovedForAll(checked);
      console.log(`Card access ${checked ? "approved" : "revoked"}`);
    } catch (error) {
      console.error("Error setting card access approval:", error);
    }
  };

  const onApprove = async () => {
    if (!stakingContract || !sfortContract) return;
    try {
      const amountToApprove = ethers.utils.parseUnits(approveAmt);

      setMainLoader(true);
      const tx = await sfortContract.approve(
        stakingContract?.address,
        amountToApprove
      );
      await tx.wait();
      notify("Allowance added successfully!", "success");
      fetchAllowancesAndBalances();
    } catch (e) {
      console.error("Error approving allowance", e);
      notify("Error approving allowance", "error");
    } finally {
      setMainLoader(false);
      setIsOpen(false);
    }
  };

  const fetchAllowancesAndBalances = useCallback(async () => {
    if (!myAddress || !sfortContract || !stakingContract) return;
    try {
      const [sfortBalance, allowance] = await Promise.all([
        sfortContract.balanceOf(myAddress),
        sfortContract.allowance(myAddress, stakingContract.address),
      ]);

      if (sfortBalance) {
        setSfortBalance(
          Math.floor(
            parseFloat(ethers.utils.formatEther(sfortBalance))
          ).toString()
        );
      }

      if (allowance) {
        setAllowance(
          Math.floor(parseFloat(ethers.utils.formatEther(allowance))).toString()
        );
      }
    } catch (e) {
      console.error("Error fetching allowances and balances", e);
    }
  }, [myAddress]);

  const onCloseAllowanceModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (myAddress) {
      fetchAllowancesAndBalances();
    }
  }, [myAddress, fetchAllowancesAndBalances]);

  const countMutants = async (
    myAddress: string | undefined,
    totalBalance: number
  ) => {
    const batchSize = 40;
    let totalMutants = 0;

    const numBatches = Math.ceil(totalBalance / batchSize);

    // Loop through each batch
    for (let i = 0; i < numBatches; i++) {
      const from = i * batchSize; // Starting index for the current batch

      try {
        // Fetch the card batch (returns 3 arrays: tokenIDs, categoryIDs, isMutant)
        const cardBatch = await grabberContract?.getCardsBatch(myAddress, from);

        if (cardBatch) {
          const isMutants = cardBatch[2]; // The third array contains boolean values for isMutant

          // Count how many `true` values are in the `isMutants` array
          const mutantCountInBatch = isMutants.filter(
            (isMutant: boolean) => isMutant
          ).length;

          // Add to the total count of mutants
          totalMutants += mutantCountInBatch;
        }
      } catch (error) {
        console.error(`Error fetching batch starting from ${from}:`, error);
      }
    }

    return totalMutants; // Return the total count of mutants
  };

  // Example usage
  const getTotalMutants = async () => {
    try {
      const totalBalance = await cardContract?.balanceOf(myAddress); // Get total balance of cards for the address
      const totalMutants = await countMutants(myAddress, totalBalance);
      console.log(`Total Mutants: ${totalMutants}`);
      return totalMutants;
    } catch (error) {
      console.error("Error counting mutants:", error);
    }
  };

  function isThursdayToSunday(date: Date): boolean {
    const day = getDay(date); // Get the day (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const hours = getHours(date); // Get the current hour

    // Thursday at 8PM
    const thursday8PM = setHours(
      setMinutes(setSeconds(new Date(date), 0), 0),
      20
    );

    if (day === 4 && isAfter(date, thursday8PM)) {
      // It's Thursday after 8PM
      return true;
    }

    if (day === 5 || day === 6) {
      // It's Friday or Saturday (any time)
      return true;
    }

    if (day === 0) {
      // It's Sunday before midnight
      return true;
    }

    return false;
  }

  const tierThresholds = [0, 1, 2, 5, 10, 20, 40]; // Tier thresholds based on count

  // Helper function to determine the current tier and progress to the next tier
  const getTierInfo = (count: number) => {
    let tier = 0;
    let nextTierCount = tierThresholds[1]; // Initial next tier count (assuming count > 0)

    for (let i = 0; i < tierThresholds.length; i++) {
      if (count >= tierThresholds[i]) {
        tier = i; // Set the current tier index
        nextTierCount = tierThresholds[i + 1] ?? Infinity; // Set the next tier threshold or Infinity if no more tiers
      } else {
        break;
      }
    }

    console.log("TIER", tier);
    // Calculate the progress percentage between the current tier and the next tier
    const progressPercentage =
      tier === tierThresholds.length - 1
        ? 100 // Max tier, 100% progress
        : ((count - tierThresholds[tier]) /
            (nextTierCount - tierThresholds[tier])) *
          100;

    return { tier, progressPercentage, nextTierCount };
  };

  return (
    <div className="mutantResearch">
      <Title title="MUTANT RESEARCH" />
      <div className="mutantDescription">
        In order to place your mutants in the research centre, you will need to
        give card access to the contract. Mutants that are staked here will
        start earning $FORT from the beginning of the following tournament and
        will be paid out at the end of every tournament. You will also need to
        stake 2000 $FORT Per Mutant staked, returnable when unstaked at any
        time.
      </div>
      <div className="icon">
        <RewardBG />
        <RewardIcon />
      </div>

      <div className="rewardLevel">
        <h3>
          YOUR REWARD LEVEL
          <span>{stakedMutants.length} MUTANTS</span>
        </h3>
        <div className="rewardProgressBar">
          <div className="level">
            <BorderStyle />
            <div
              className="progressBar"
              style={{ width: `${progressPercentage}%` }}
            >
              <BorderStyle />
            </div>
          </div>
          <div className="status">
            {/* Display the current tier on the left side of the progress bar */}
            <label className="currentTier">
              Tier {currentTier}{" "}
              <span>{100 + (currentTier - 1) * 10}% rewards</span>
            </label>

            {/* Display the next tier on the right side of the progress bar */}
            <label className="nextTier">
              {currentTier < tierThresholds.length - 1
                ? `Tier ${currentTier + 1} `
                : `Tier ∞ `}
              <span>
                {currentTier < tierThresholds.length - 1
                  ? `${100 + currentTier * 10}% rewards`
                  : `∞ rewards`}
              </span>
            </label>
          </div>
        </div>

        <ToggleSwitch
          checked={cardAccess}
          onChange={toggleCardAccess}
          title="CARD ACCESS"
        />
        <div className="mainPhoto">
          <img src={mainPhotoBg} alt="Main" />
        </div>
      </div>
      <div className="stakeAll">
        {stakedMutants.map((mutant, index) => (
          <div className="box" key={index}>
            <img src={mutant.imageUrl} alt="Stake" />
            <div className="item">
              <span>STAKE</span>
              <ToggleSwitch
                onChange={(toggle) => console.log("toggle", toggle)}
                checked={true}
                disabled={isThursdayToSunday(new Date())}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="totalStaked">
        <BorderStyle />
        <h4>Total Staked</h4>
        <h4 className="sfort">
          {" "}
          <span>{stakedCount} MUTANTS</span>
          <span>{stakedSFORT} $FORT</span>
        </h4>
        <h4>Total unstaked</h4>
        <div className="mtntSFort">
          <p>
            <span>{unstakedMutants}</span> MUTANTS
          </p>
          <p>
            <span>{costStake}</span>$FORT
          </p>
        </div>
        <div className="actionButtons">
          <PrimaryButton
            disabled={isThursdayToSunday(new Date())}
            text="STAKE ALL"
            onClick={handleStakeAll}
          />
          <PrimaryButton text="UNSTAKE ALL" onClick={handleUnstakeAll} />
        </div>
      </div>
      <div className="total">
        <div className="box">
          <BorderStyle />
          <h5>TOTAL POOL</h5>
          <h3>{totalPool}</h3>
        </div>
        <div className="box">
          <BorderStyle />
          <h5>PENDING REWARDS</h5>
          <h3>{estimatedReward}</h3>
        </div>

        {/*
        <div className="box">
          <BorderStyle />
          <h5>YOUR SHARE</h5>
          <h3>25%</h3>
        </div>
        <div className="box">
          <BorderStyle />
          <h5>NEXT REWARD</h5>
          <h3>00:00:00</h3>
        </div>
      */}
      </div>

      <AllowancePopup
        title="Mutant Research Allowance"
        balanceAllowance={Number(allowance)}
        onClickAdd={() => setIsOpen(true)}
      />
      <AllowanceModal
        open={modalIsOpen}
        onClose={onCloseAllowanceModal}
        userTokenBalance={Number(sfortBalance)}
        balance={Number(sfortBalance)}
        approveAmt={approveAmt}
        setApproveAmt={setApproveAmt}
        onApprove={onApprove}
      />

      <div className="mutantFooter">
        <MutantFooter />
      </div>
      <InfoModal
        openModal={isMutantClosed}
        closeModal={() => setMutantClosed(false)}
        handleBack={() => setMutantClosed(false)}
        title="SORRY WE ARE CLOSED"
        description="You're welcome to shuffle any mutants in from Monday to Thursday. And do not try to sneak one in on a Friday. Thats gonna cost ya a week of rewards. Oke? Thank you."
      />
      {mainLoader && <Loader />}
    </div>
  );
};

export default MutantResearch;
