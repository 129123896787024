import React from "react";
import "./ToggleSwitch.scss";

type Props = {
  onChange: (checked: boolean) => void;
  title?: string;
  checked: boolean;
  disabled?: boolean;
};

const ToggleSwitch: React.FC<Props> = ({
  onChange,
  title,
  checked,
  disabled = false,
}) => {
  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <div className={`toggleButton ${disabled ? "disabled" : ""}`}>
      {title ? (
        <p className="text-[#707070] text-center mb-2">{title}</p>
      ) : undefined}
      <label className="switch">
        <span className="topCornerLeftDots"></span>
        <span className="topCornerRightDots"></span>
        <span className="bottomCornerLeftDots"></span>
        <span className="bottomCorneRightDots"></span>
        <input
          type="checkbox"
          onChange={handleToggleChange}
          checked={checked}
          disabled={disabled}
        />
        <span className="slider">
          <span className="sliderButton">
            <span className="topCornerLeftDots"></span>
            <span className="topCornerRightDots"></span>
            <span className="bottomCornerLeftDots"></span>
            <span className="bottomCorneRightDots"></span>
            <span className="extraShadow"></span>
          </span>
        </span>
      </label>
    </div>
  );
};

export default ToggleSwitch;
