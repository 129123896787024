import React, { useEffect, useState } from "react";
import Title from "../../Components/Common/Title/Title";
import defualtAvatar from "../../assets/images/profile-picture.png";
import { ThreeDots } from "react-loader-spinner";
import { pico1v1Contract } from "../../web3/contracts";
import { useWeb3 } from "../../web3/Web3Context";
import "./AwaitingOpponent.scss";
import PrimaryButton from "../../Components/Common/UI/PrimaryButton/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { shortenAddress } from "../../utils/shortenAddress";
import { findValidProfilePicUrl } from "../../utils/findValidProfilePicUrl";
import { ethers } from "ethers";
import Loader from "../../Components/Common/Loader/Loader";
import LoaderImage from "../../assets/images/LoaderSquare.gif";
import InfoModal from "../../Components/Common/Modals/InfoModal/InfoModal";
const AwaitingOpponent = () => {
  const navigate = useNavigate();
  const { myAddress } = useWeb3();
  const [gameCountDown, setGameCountDown] = useState(false);
  const [countdownValue, setCountdownValue] = useState(5); // Start from 5
  const [roomDetail, setRoomDetail] = useState<any>(null);
  const [mainLoader, setMainLoader] = useState<boolean>(false);
  const [creatorProfile, setCreatorProfile] = useState({
    picture: defualtAvatar,
    name: "",
  });
  const [joinerProfile, setJoinerProfile] = useState({
    picture: defualtAvatar,
    name: "",
  });
  const [isRoomCancelled, setIsRoomCancelled] = useState<boolean>(false);
  useEffect(() => {
    const fetchRoomDetails = async () => {
      if (!pico1v1Contract || !myAddress) {
        console.error("pico1v1Contract or myAddress is not available.");
        return;
      }

      try {
        const roomId = await pico1v1Contract
          ?.users(myAddress)
          .then((user: any) => user.currentroom);
        if (roomId && roomId !== 0) {
          const roomData = await pico1v1Contract.rooms(roomId);
          setRoomDetail({
            creator: roomData.creator,
            joiner: roomData.joiner,
            bet: roomData.bet,
          });

          if (roomData.creator) {
            const creatorPic = await findValidProfilePicUrl(roomData.creator);
            const creatorName = await fetchUsername(roomData.creator);
            setCreatorProfile({
              picture: creatorPic || defualtAvatar,
              name: creatorName || shortenAddress(roomData.creator),
            });
          }

          if (
            roomData.joiner &&
            roomData.joiner !== "0x0000000000000000000000000000000000000000"
          ) {
            const joinerPic = await findValidProfilePicUrl(roomData.joiner);
            const joinerName = await fetchUsername(roomData.joiner);
            setJoinerProfile({
              picture: joinerPic || defualtAvatar,
              name: joinerName || shortenAddress(roomData.joiner),
            });
            setGameCountDown(true); // Start the countdown only if a valid joiner is detected
          }
        } else {
          console.error("No active room found for the user.");
        }
      } catch (error) {
        console.error("Error fetching room details:", error);
      }
    };

    fetchRoomDetails();

    // Polling to check for a joiner every 3 seconds
    const intervalId = setInterval(async () => {
      try {
        const roomId = await pico1v1Contract
          ?.users(myAddress)
          .then((user: any) => user.currentroom);
        if (roomId && roomId !== 0) {
          const roomData = await pico1v1Contract?.rooms(roomId);
          if (
            roomData.joiner &&
            roomData.joiner !== "0x0000000000000000000000000000000000000000"
          ) {
            const joinerPic = await findValidProfilePicUrl(roomData.joiner);
            const joinerName = await fetchUsername(roomData.joiner);
            setJoinerProfile({
              picture: joinerPic || defualtAvatar,
              name: joinerName || shortenAddress(roomData.joiner),
            });
            setGameCountDown(true); // Start the countdown if a joiner is detected
            clearInterval(intervalId); // Stop polling once a joiner is detected
          }
        }
      } catch (error) {
        console.error("Error polling room details:", error);
      }
    }, 3000); // Poll every 3 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [myAddress]);

  useEffect(() => {
    let countdownInterval: NodeJS.Timeout;

    if (gameCountDown) {
      countdownInterval = setInterval(() => {
        setCountdownValue((prevValue) => prevValue - 1);
      }, 1000);

      if (countdownValue === 0) {
        clearInterval(countdownInterval);
        navigate("/game-board");
      }
    }

    return () => {
      clearInterval(countdownInterval);
    };
  }, [gameCountDown, countdownValue]);

  const fetchUsername = async (address: string): Promise<string | null> => {
    try {
      const response = await fetch(`/api/fetchUsername?address=${address}`);
      if (response.ok) {
        const data = await response.json();
        return data.username || null;
      } else {
        console.error("Failed to fetch username");
      }
    } catch (error) {
      console.error("Error fetching username:", error);
    }
    return null;
  };

  //   const oncancelRoom = async () => {
  //     const overrides = {gasLimit: ethers.utils.hexlify(800000)};
  //     const tx = await pico1v1Contract?.cancelRoom(overrides);
  //     await tx.wait();
  //     navigate("/");
  //   }

  const oncancelRoom = async () => {
    try {
      setMainLoader(true); // Start the loader
      const overrides = { gasLimit: ethers.utils.hexlify(8000000) };
      const tx = await pico1v1Contract?.cancelRoom(overrides);
      await tx.wait();
      setIsRoomCancelled(true);
      //   navigate("/");
    } catch (error) {
      console.error("Error cancelling the room:", error);
      // Handle the error accordingly (e.g., show an error message to the user)
    } finally {
      setMainLoader(false); // Stop the loader
    }
  };

  return (
    <div>
      <Title title="Awaiting Opponent" />
      <div className="description">
        <p>
          Please wait for your opponent to join your room. The game will start
          shortly after.
        </p>
      </div>
      <div className="awaitingOpponent flex justify-between items-center">
        {/* Creator Details */}
        <div className="flex flex-col items-center">
          <div className="playerBorderStyles">
            <span className="topCornerLeftDots"></span>
            <span className="topCornerRightDots"></span>
            <span className="bottomCornerLeftDots"></span>
            <span className="bottomCorneRightDots"></span>
            <span className="extraShadow"></span>
            <img
              src={creatorProfile.picture}
              alt="Creator Avatar"
              width={121}
              height={121}
            />
          </div>
          <p className="text-xs pt-4 font-light">{creatorProfile.name}</p>
        </div>

        {/* VS Section */}
        <div className="text-[27px] text-white font-dinosaur">
          {gameCountDown ? (
            <p>Get ready to play in {countdownValue}...</p>
          ) : (
            <p>VS</p>
          )}
        </div>

        {/* Joiner Details */}
        <div className="flex flex-col items-center">
          <div className="relative">
            {roomDetail?.joiner &&
            roomDetail.joiner !==
              "0x0000000000000000000000000000000000000000" ? (
              <div className="playerBorderStyles">
                <span className="topCornerLeftDots"></span>
                <span className="topCornerRightDots"></span>
                <span className="bottomCornerLeftDots"></span>
                <span className="bottomCorneRightDots"></span>
                <span className="extraShadow"></span>
                <img
                  src={joinerProfile.picture}
                  alt="Joiner Avatar"
                  width={121}
                  height={121}
                />
              </div>
            ) : (
              //   <ThreeDots
              //     height="80"
              //     width="80"
              //     radius="9"
              //     color="#4fa94d"
              //     ariaLabel="three-dots-loading"
              //     wrapperStyle={{}}
              //     visible={true}
              //   />
              <img src={LoaderImage} alt="User" />
            )}
          </div>
          <p className="text-xs pt-4 font-light text-center">
            {roomDetail?.joiner &&
            roomDetail.joiner !== "0x0000000000000000000000000000000000000000"
              ? joinerProfile.name
              : "???"}
          </p>
        </div>
      </div>
      <div className="cancelRoom mt-4">
        <PrimaryButton text="Cancel Room" onClick={oncancelRoom} className="" />
      </div>
      <InfoModal
        openModal={isRoomCancelled}
        title="Room Cancelled"
        description="Where do you want me to take you?"
        closeModal={() => navigate("/")}
        handleMainButton={() => navigate("/host-game")}
        mainButtonText="Host Room"
        secondButtonText="Join Room"
        handleBack={() => navigate("/join-game")}
        hideMainButton={false}
        hideSecondButton={false}
      />
      {mainLoader && <Loader />}
    </div>
  );
};

export default AwaitingOpponent;
