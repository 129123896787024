import React from "react";
import LogoLoader from "../../../assets/images/Logo_PiCO_Jumping.gif";
import Combo2x from "../../../assets/images/comboCards/2XCOMBO.gif";
import Combo3x from "../../../assets/images/comboCards/3XCOMBO.gif";
import "./Loader.scss";
type Props = {
  comboSize?: any;
};
const Loader: React.FC<Props> = ({ comboSize }) => {
  return (
    <div className="loader">
      <img
        src={comboSize === 2 ? Combo2x : comboSize === 3 ? Combo3x : LogoLoader}
        alt="Loader"
      />
    </div>
  );
};

export default Loader;
