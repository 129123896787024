import React from "react";
import Modal from "../../Modal/Modal";
import PrimaryButton from "../../UI/PrimaryButton/PrimaryButton";
import Gif from "../../../../assets/images/gif.png";
import "./InfoModalWithImage.scss";
export type ModalState = boolean;

type Props = {
  openModal: boolean;
  description: string;
  img?: string;
  closeModal?: () => void;
  handleMainButton?: () => void;
  handleBack?: () => void;
  mainButtonText?: string;
  secondButtonText?: string;
  hideMainButton?: boolean;
  gif?: boolean;
};
const InfoModalWithImage: React.FC<Props> = ({
  openModal,
  description,
  closeModal,
  handleBack,
  handleMainButton,
  hideMainButton = true,
  secondButtonText,
  mainButtonText,
  gif = false,
  img,
}) => {

  return openModal ? (
    <Modal open={true} onClose={() => {}} className="infoWithImage">
      <div className="body">
        <div className="closeModal" onClick={() => closeModal}>
          x
        </div>
        {gif && (
          <div className="gif">
            <img src={Gif} alt="Gif" />
          </div>
        )}
        <div className="bodyImage">
          <img src={img} alt={""} />
        </div>
        <p className="description">{description}</p>

        <div className="actionButtons">
          {!hideMainButton && (
            <PrimaryButton
              text={mainButtonText ?? "CARD SHOP"}
			  width="180px"
              onClick={handleMainButton}
            />
          )}
          <PrimaryButton
            text={secondButtonText ?? "BACK"}
			width="180px"
            onClick={handleBack}
          />
        </div>
      </div>
    </Modal>
  ) : (
    <></>
  );
};

export default InfoModalWithImage;
