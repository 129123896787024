// src/features/navbar/navbarSlice.js
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ProfileState {
  tier: string | undefined;
  playerCards: any;
  hasPermissionToPlay: any;
  isPlayerRulesModalOpen: boolean;
  playerRulesModalDetailsContent: any;
  userName: string;
}

const initialState: ProfileState = {
  tier: undefined,
  playerCards: undefined,
  hasPermissionToPlay: undefined,
  isPlayerRulesModalOpen: false,
  playerRulesModalDetailsContent: "",
  userName: "",
};

const profileSlide = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setUserTier(state, action: PayloadAction<any>) {
      state.tier = action.payload;
    },
    setProfileUserName(state, action: PayloadAction<any>) {
      console.log("SADA TE", state.userName);
      state.userName = action.payload;
    },
    setPlayerCards(state, action: PayloadAction<any>) {
      state.playerCards = action.payload;
    },
    setPermissionToPlay(state, action: PayloadAction<any>) {
      state.hasPermissionToPlay = action.payload;
    },
    setPlayerRulesModalOpen(state, action: PayloadAction<any>) {
      state.isPlayerRulesModalOpen = action.payload;
    },
    setPlayerRulesModalDetails(state, action: PayloadAction<any>) {
      state.playerRulesModalDetailsContent = action.payload;
    },

    // Add other actions if needed
  },
});

export const {
  setUserTier,
  setPlayerCards,
  setPermissionToPlay,
  setPlayerRulesModalOpen,
  setPlayerRulesModalDetails,
  setProfileUserName,
} = profileSlide.actions;
export default profileSlide.reducer;
