import React from "react";
import Modal from "../../Modal/Modal";
import PrimaryButton from "../../UI/PrimaryButton/PrimaryButton";
import BifrostWallet from '../../../../assets/images/wallet/BifrostWallet.svg'
import BraveWallet from '../../../../assets/images/wallet/BraveWallet.svg'
import CoinbaseWallet from '../../../../assets/images/wallet/CoinbaseWallet.svg'
import Metamask from '../../../../assets/images/wallet/Metamask.svg'
import WalletConnect from '../../../../assets/images/wallet/WalletConnect.svg'

import './WalletModal.scss'
export type ModalState = boolean;

type Props = {
  openModal: boolean;
  title: string;
  closeModal?: () => void;
  handleMainButton?: () => void;
  handleBack?: () => void;
  mainButtonText?: string;
  secondButtonText?: string;
  hideMainButton?: boolean;
};

const WalletModal: React.FC<Props> = ({ openModal, title, closeModal, handleMainButton, handleBack, mainButtonText, hideMainButton = true, secondButtonText}) => {
  if (!openModal) return null;

  return (
    <Modal open={openModal} onClose={() => closeModal}>
      <div className="congratulations">
        <div className="closeModal" onClick={closeModal}>x</div>
        <p className="leader-board-title" style={{color: 'white'}}>
          {title}
        </p>
	<div className="walletIcons">
		<img src={BifrostWallet} alt="Wallet" />
		<img src={BraveWallet} alt="Wallet" />
		<img src={CoinbaseWallet} alt="Wallet" />
		<img src={Metamask} alt="Wallet" />
		<img src={WalletConnect} alt="Wallet" />
	</div>
        <div className="buttons">
          {hideMainButton && <PrimaryButton text={mainButtonText ?? "MORE OPTIONS"} onClick={handleMainButton} width="180px" />}
          <PrimaryButton text={secondButtonText ?? "BACK"} onClick={handleBack} width="180px" />
        </div>
      </div>
    </Modal>
  );
};

export default WalletModal;
