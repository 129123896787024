import React from "react";
import './HostInput.scss';

interface Props {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const HostInput: React.FC<Props> = ({ value, onChange }) => {
  return (
	<>
	<div className="hostInputTitle">GAME ROOM FEE</div>
    <div className="hostInput">
      <p>FEE</p>
      <h2>
        <input
          type="text"
          min="0"
          value={value}
          onChange={onChange}
        />
        $FORT
      </h2>
    </div>
	</>
  );
};

export default HostInput;
