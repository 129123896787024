import React, { useState, useEffect } from "react";
import Title from "../../Components/Common/Title/Title";
import EditIcon from "../../assets/images/edit.png";
import PrimaryButton from "../../Components/Common/UI/PrimaryButton/PrimaryButton";
import defualtAvatar from "../../assets/images/profile-picture.png";
import { useWeb3 } from "../../web3/Web3Context"; // Assuming you have this context for web3
import { provider } from "../../web3/contracts"; // Import your provider here
import { waitRefresh } from '../../utils/waitRefresh';
import { findValidProfilePicUrl } from '../../utils/findValidProfilePicUrl';

import './ProfileSettings.scss';
import { Link } from "react-router-dom";

type Props = {}

interface Profile {
    name: string;
    picture: any;
}

const ProfileSettings: React.FC<Props> = () => {
    const { myAddress, signer } = useWeb3(); // Get myAddress and signer from Web3Context
    const [enableSaveNameBtn, setEnableSaveNameBtn] = useState<boolean>(false);
    const [profile, setProfile] = useState<Profile>({
        name: "",
        picture: "",
    });
    const [previewUrl, setPreviewUrl] = useState<string>(defualtAvatar);

  // Fetch the username and profile picture when the component mounts
  useEffect(() => {
    const fetchProfile = async () => {
        if (myAddress) {
            // Fetch the profile picture
            const validPicUrl = await findValidProfilePicUrl(myAddress);
            if (validPicUrl) {
                setPreviewUrl(validPicUrl);
            }

            // Fetch the username using the API route
            try {
                const response = await fetch(`/api/fetchUsername?address=${myAddress}`);
                if (response.ok) {
                    const data = await response.json();
                    setProfile(prevProfile => ({ ...prevProfile, name: data.username }));
                } else {
                    console.error("Failed to fetch username");
                }
            } catch (error) {
                console.error("Error fetching username:", error);
            }
        }
    };
    fetchProfile();
  }, [myAddress]);


    function handleNameChange(e: React.ChangeEvent<HTMLInputElement>) {
        const sanitizedValue = e.target.value
            .toLowerCase()
            .replace(/[^a-z0-9]/g, "");
        setProfile({ ...profile, name: sanitizedValue });
        setEnableSaveNameBtn(true); // Enable save button when name is changed
    }

    const handleNameSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (!provider || !signer || !myAddress) {
            console.error("Provider, signer, or myAddress is not available.");
            return;
        }

        if (profile.name.length > 16) {
            alert("Username must be 16 characters or less.");
            return;
        }

        try {
            // Step 1: Get the latest block hash for signing
            const latestBlock = await provider.getBlock("latest");
            const latestBlockHash = latestBlock.hash;

            // Step 2: Create the message and sign it
            const message = `Authorize PiCO Username Change: ${latestBlockHash}`;
            const signature = await signer.signMessage(message);

            // Step 3: Prepare the form data
            const formData = new FormData();
            formData.append('newusername', profile.name); // Append the new username
            formData.append('address', myAddress);
            formData.append('signature', signature);
            formData.append('message', message);

            // Step 4: Send the data to the server via POST
            const response = await fetch('https://pico.cryptagion.com/picoupdateusername.php', {
                method: 'POST',
                body: formData
            });
            setEnableSaveNameBtn(false); 
            waitRefresh(1);
            // Handle the response
            //const result = await response.text();
            //console.log(result);

          

        } catch (error) {
            console.error("Error during username update:", error);
           // alert("An error occurred during the username update. Please try again.");
           setEnableSaveNameBtn(false); 
           waitRefresh(1);        
        }
    };

    const handleProfileImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const file = files[0]; // Get the selected file

            if (file.size > 1048576) {
                alert("The selected file is too large. Please select a file smaller than 1 MB.");
                return; // Exit if the file is too large
            }

            if (!provider || !signer || !myAddress) {
                console.error("Provider, signer, or myAddress is not available.");
                return;
            }

            try {
                // Step 3: Get the latest block hash for signing
                const latestBlock = await provider.getBlock("latest");
                const latestBlockHash = latestBlock.hash;

                // Step 4: Create the message and sign it
                const message = `Authorize PiCO Profile Picture: ${latestBlockHash}`;
                const signature = await signer.signMessage(message);

                // Step 5: Prepare the form data
                const formData = new FormData();
                formData.append('file', file); // Append the selected file
                formData.append('address', myAddress);
                formData.append('signature', signature);
                formData.append('message', message);

                // Step 6: Send the file to the server via POST
                const response = await fetch('https://pico.cryptagion.com/picoupload.php', {
                    method: 'POST',
                    body: formData
                });

                // Step 7: After upload, find the valid profile picture URL and update the UI
                const mypicurl = await findValidProfilePicUrl(myAddress);
                waitRefresh(1);

                if (mypicurl) {
                    setPreviewUrl(mypicurl); // Update the previewUrl state to show the new picture
                } else {
                    setPreviewUrl(defualtAvatar); // Default back to placeholder image if no valid picture found
                    console.error("No valid profile picture found.");
                }
            } catch (error) {
               // Step 7: After upload, find the valid profile picture URL and update the UI
               const mypicurl = await findValidProfilePicUrl(myAddress);
               waitRefresh(1);

               if (mypicurl) {
                   setPreviewUrl(mypicurl); // Update the previewUrl state to show the new picture
               } else {
                   setPreviewUrl(defualtAvatar); // Default back to placeholder image if no valid picture found
                   console.error("No valid profile picture found.");
               }





                console.error("Error during file upload or profile picture update:", error);
            }
        }
    };

    return (
        <>
            <Title title="PROFILE SETTINGS" />
            {/* Username Change Section */}
            <form className="transition-all">
              <div className="profileUserName">
                  <div>
                      <p className="profileTitle">Username</p>
                      <button
                          onClick={(e) => {
                              e.preventDefault();
                              setEnableSaveNameBtn(!enableSaveNameBtn);
                          }}
                      >
                          <img src={EditIcon} alt="Edit Icon" />
                      </button>
                  </div>
                  <div className="profileName">
                      {!enableSaveNameBtn ? (
                          <label className="currentUsername">{profile.name}</label>
                      ) : (
                          <input
                              className="inputStyle transition-all text-center"
                              type="text"
                              value={profile.name}
                              onChange={(e) => handleNameChange(e)}
                          />
                      )}
                  </div>
                  
              </div>
          </form>


            {/* Profile Picture Upload Section */}
            <p className="profileTitle">Profile Image</p>
            <div className="userAvatar">
                <img
                    src={previewUrl}
                    alt="Profile Picture"
                    width={281}
                    height={281}
                    className="rounded-full"
                />
            </div>

            <div className="">
                <label
                    htmlFor="uploadImage"
                    className="primary-button-styles uploadUserPhotoButton"
                >
                    Upload Image
                    <span className="topCornerLeftDots"></span>
                    <span className="topCornerRightDots"></span>
                    <span className="bottomCornerLeftDots"></span>
                    <span className="bottomCorneRightDots"></span>
                    <span className="extraShadow"></span>
                    <input
                        id="uploadImage"
                        className="opacity-0 absolute"
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={handleProfileImageChange}
                    />
                </label>
				{enableSaveNameBtn && (
                      <div className="actionButtons">
                          <PrimaryButton
                              className="px-10 mt-6"
                              text={"Save Username"}
                              width="244px"
                              onClick={handleNameSubmit}
                          />
                      </div>
                  )}

				<div className="actionButtons"><Link to="/"><PrimaryButton text="Back" /></Link></div>
            </div>
        </>
    )
}

export default ProfileSettings;
