import React, { useEffect, useState } from "react";
import defualtAvatar from "../../../../assets/images/profile-picture.png";
import { ReactComponent as ClockIcon } from '../../../../assets/images/clock.svg';
import { ReactComponent as UnionIcon } from '../../../../assets/images/union.svg';
import BorderStyle from "../../../../Components/Common/BorderStyle/BorderStyle";
import './PlayerInfo.scss';
import { findValidProfilePicUrl } from "../../../../utils/findValidProfilePicUrl";

type Props = {
    miliseconds?: number;
    total?: number;
    playerAddress: string | undefined;
    isTurn?: boolean; // Prop to indicate if it's the player's turn
};

const PlayerInfo: React.FC<Props> = ({ miliseconds, total = 3, playerAddress, isTurn }) => {
    const [profilePic, setProfilePic] = useState<string>(defualtAvatar);
    const [username, setUsername] = useState<string>("N/A");
    const [remainingTime, setRemainingTime] = useState<any>(miliseconds); // State to manage countdown
    useEffect(() => {
        const fetchProfile = async () => {
            if (playerAddress) {
                const validPicUrl = await findValidProfilePicUrl(playerAddress);
                const fetchedUsername = await fetchUsername(playerAddress);
                setProfilePic(validPicUrl || defualtAvatar);
                setUsername(fetchedUsername || shortenAddress(playerAddress));
            }
        };
        fetchProfile();
    }, [playerAddress]);

    // useEffect(() => {
    //     let timerId: NodeJS.Timeout | null = null;

    //     if (isTurn) {
    //         timerId = setInterval(() => {
    //             setRemainingTime((prevTime: any) => {
	// 				console.log('prevTime', prevTime)
    //                 if (prevTime > 0) {
    //                     return prevTime - 10; // Decrease time by 10 milliseconds
    //                 } else {
    //                     clearInterval(timerId!);
    //                     return 0; // Stop countdown at 0
    //                 }
    //             });
    //         }, 10);
    //     }

    //     return () => {
    //         if (timerId) {
    //             clearInterval(timerId);
    //         }
    //     };
    // }, [isTurn]);


	useEffect(() => {
		if(isTurn) {
			if (miliseconds !== undefined) {
				setRemainingTime(miliseconds);
				const startTime = Date.now();
				const countdown = () => {
					const elapsed = Date.now() - startTime;
					const remaining = miliseconds - elapsed;
	
					setRemainingTime(remaining > 0 ? remaining : 0);
		
					if (remaining > 0) {
						requestAnimationFrame(countdown);
					}
				};
		
				countdown(); // Start the countdown
		
				return () => {
					setRemainingTime(0); // Reset the timer when the effect is cleaned up
				};
			}
		} else {
			setRemainingTime(0);
		}
		
	}, [isTurn, miliseconds]);

    const formatTime = (totalMilliseconds: number): string => {
        const minutes = Math.floor(totalMilliseconds / 60000);
        const seconds = Math.floor((totalMilliseconds % 60000) / 1000);
        const milliseconds = Math.floor((totalMilliseconds % 1000) / 10);

        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}:${milliseconds.toString().padStart(2, '0')}`;
    };

	// const formatTime = (totalMilliseconds: number): string => {
	// 	// Ensure totalMilliseconds is a valid number
	// 	if (isNaN(totalMilliseconds) || totalMilliseconds < 0) {
	// 		return '00:00:00';
	// 	}
	
	// 	const minutes = Math.floor(totalMilliseconds / 60000);
	// 	const seconds = Math.floor((totalMilliseconds % 60000) / 1000);
	// 	const milliseconds = Math.floor((totalMilliseconds % 1000) / 10);
	
	// 	// Format as MM:SS:MS
	// 	return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}:${milliseconds.toString().padStart(2, '0')}`;
	// };
	

    const elements = Array.from({ length: total }).map((_, index) => (
        <UnionIcon key={index} />
    ));

    const shortenAddress = (address: string): string => {
        return `${address.slice(0, 6)}...${address.slice(-4)}`;
    };

    const fetchUsername = async (address: string): Promise<string | null> => {
        try {
            const response = await fetch(`/api/fetchUsername?address=${address}`);
            if (response.ok) {
                const data = await response.json();
                return data.username || null;
            } else {
                console.error("Failed to fetch username");
            }
        } catch (error) {
            console.error("Error fetching username:", error);
        }
        return null;
    };

    return (
        <div className={`playerInfo ${isTurn ? 'activeTurn' : ''}`}>
            <div className="avatar">
                <img src={profilePic} alt="Profile Avatar" />
                <BorderStyle />
            </div>
            <div className="player">
                <label>{username}</label>
                <span>#</span>
                <BorderStyle />
            </div>
            <div className={`${isTurn ? 'active' : ''} timer`}>
                <ClockIcon />
                <span>{isTurn ? formatTime(remainingTime) : '00:00:00'}</span>
                <BorderStyle />
            </div>
            <div className={`${isTurn ? 'active' : ''} union`}>
                {elements}
            </div>
        </div>
    );
};

export default PlayerInfo;
