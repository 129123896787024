import { toast } from 'react-hot-toast';

export const notify = (message: string, type: string) => {
  // Dismiss all toasts currently displayed
  toast.dismiss();

  // Depending on the 'type' specified, display the appropriate toast
  switch (type) {
    case 'success':
      toast.success(message, {
        duration: 5000,
        style: {
          border: '1px solid gray',
        },
      });
      break;
    case 'error':
      toast.error(message, {
        duration: 5000,
        style: {
          border: '1px solid gray',
        },
      });
      break;
    default:
      toast(message);
  }
}

export const messaging = (message: string) => {
  toast.dismiss();
  toast(message, {
    duration: 5000,
    style: {
      border: '1px solid gray',
    },
  });
}