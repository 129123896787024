import React from "react";
import DefualtAvatar from "../../assets/images/profile-picture.png";
import Rank1Icon from "../../assets/images/rankIcons/rank1.svg";
import Rank2Icon from "../../assets/images/rankIcons/rank2.svg";
import Rank3Icon from "../../assets/images/rankIcons/rank3.svg";
import Rank4Icon from "../../assets/images/rankIcons/rank4.svg";
import Rank5Icon from "../../assets/images/rankIcons/rank5.svg";
import WonIcon from "../SVGComponents/WonIcon";

interface LeaderboardEntry {
  rank: string;
  profilePicture?: string;
  userName?: string;
  address: string;
  fortWon?: string;
  gamesWon: string;
  gamesPlayed: string; // Added gamesPlayed
}

interface TableProps {
  data: LeaderboardEntry[];
}

const LeaderBoardList: React.FC<TableProps> = ({ data }) => {
  const getRankIcon = (rank: string) => {
    switch (rank.toString()) {
      case "1":
        return Rank1Icon;
      case "2":
        return Rank2Icon;
      case "3":
        return Rank3Icon;
      case "4":
        return Rank4Icon;
      default:
        return Rank5Icon;
    }
  };

  const getRowColorByRank = (rank: string) => {
    switch (rank.toString()) {
      case "1":
        return "#F0EBC0";
      case "2":
        return "#EAEAF4";
      case "3":
        return "#E3CEB8";
      case "4":
        return "#D9D9D9";
      default:
        return "#A0A0A0";
    }
  };

  return (
    <div className="content">
      <div className="overflow-auto">
        <div className="leader-board-main-content">
          {data.map((row) => (
            <div key={row.rank} className="leader-board-body">
              <div className="leader-board-content">
                <div className="rank">
                  <div className="rankImage">
                    <img src={getRankIcon(row.rank)} alt="" width={50} />
                    <span>{row.rank}</span>
                  </div>
                </div>
                <div className="avatar">
                  <img
                    src={row.profilePicture || DefualtAvatar}
                    alt="User Profile"
                  />
                </div>
                <div className="userName">
                  <div
                    className="leader-board-user-name"
                    style={{ color: getRowColorByRank(row.rank) }}
                  >
                    {row.userName || row.address}
                  </div>
                </div>
                <div
                  className="totalWins"
                  style={{ color: getRowColorByRank(row.rank) }}
                >
                  {row.gamesWon} <span> Wins</span> / {row.gamesPlayed}{" "}
                  <span> Played</span> {/* Display wins/played */}
                </div>
                <div className="forth">
                  <WonIcon fill={getRowColorByRank(row.rank)} />
                  <div
                    className="totalPrice"
                    style={{ color: getRowColorByRank(row.rank) }}
                  >
                    {row.fortWon || "0"} $FORT
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LeaderBoardList;
