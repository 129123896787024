import { Card, Condition } from "./types";

interface CardStats {
  cattypeRarityCounts: Record<string, number>;
  minRequiredPerCattypeAndRarity: Record<string, number>;
  deckCount: number;
}

// TRESHOLDCONDITION
// Condition 1: Checks if each cattype has enough cards based on a threshold
export const thresholdCondition: Condition = (cardsList: Card[]) => {
  const totalCards = cardsList.length;
  const threshold = Math.floor(totalCards / 5); // Use Math.ceil or Math.round as needed

  // List of expected cattypes (e.g., [0, 1, 2, ...])
  const expectedCatTypes = [0, 1, 2]; // Update as per your requirements

  // Initialize an object to store the count of each `cattype`
  const cardsByCatType: Record<number, number> = cardsList.reduce(
    (acc, card) => {
      acc[card.cattype] = (acc[card.cattype] || 0) + 1;
      return acc;
    },
    {} as Record<number, number>
  );

  // Ensure all expected cattypes are present and meet the threshold
  return expectedCatTypes.every(
    (catType) => cardsByCatType[catType] && cardsByCatType[catType] >= threshold
  );
};

//CATTYPERARITYCONDITION
// Condition 2: Checks if each cattype/rarity combination meets the required minimum based on deck count
export const cattypeRarityCondition: Condition = (cardsList: Card[]) => {
  const totalCards = cardsList.length;
  const DECK_SIZE = 13;
  const deckCount = Math.floor(totalCards / DECK_SIZE) || 1;

  // Initialize an object to hold counts for each cattype/rarity combination
  const cattypeRarityCounts: Record<string, number> = {
    cattype0_rarity0: 0,
    cattype0_rarity1: 0,
    cattype0_rarity2: 0,
    cattype1_rarity0: 0,
    cattype1_rarity1: 0,
    cattype1_rarity2: 0,
    cattype2_rarity0: 0,
    cattype2_rarity1: 0,
    cattype2_rarity2: 0,
  };

  // Count each cattype/rarity combination in the cardsList
  cardsList.forEach((card) => {
    const { cattype, rarity } = card;
    const key = `cattype${cattype}_rarity${rarity}`;
    if (cattypeRarityCounts[key] !== undefined) {
      cattypeRarityCounts[key] += 1;
    }
  });

  // Calculate the minimum required for each cattype/rarity based on deck count
  const minRequiredPerCattypeAndRarity = Object.keys(
    cattypeRarityCounts
  ).reduce((acc: Record<string, number>, key) => {
    acc[key] = deckCount;
    return acc;
  }, {});

  // Check if each cattype/rarity meets the minimum required
  const unmetRequirements: Record<string, { have: number; need: number }> = {};
  let conditionMet = true;

  for (const key in cattypeRarityCounts) {
    if (cattypeRarityCounts[key] < minRequiredPerCattypeAndRarity[key]) {
      conditionMet = false;
      unmetRequirements[key] = {
        have: cattypeRarityCounts[key],
        need: minRequiredPerCattypeAndRarity[key],
      };
    }
  }

  if (conditionMet) {
    return {
      status: true,
      message: "Cattype/Rarity condition met.",
      details: {
        totalCards,
        deckCount,
        cattypeRarityCounts,
        minRequiredPerCattypeAndRarity,
      },
    };
  } else {
    return {
      status: false,
      message: "Cattype/Rarity condition not met. Improvement needed.",
      details: {
        totalCards,
        deckCount,
        cattypeRarityCounts,
        minRequiredPerCattypeAndRarity,
        unmetRequirements,
      },
    };
  }
};

//CARDCOUNTRANGECONDITION
// Condition 2: - Card count range check (minimum 27, maximum 500)
export const cardCountRangeCondition: Condition = (cardsList: Card[]) => {
  const totalCards = cardsList.length;

  // Check if totalCards is within the range [27, 500]
  const isValidRange = totalCards >= 27 && totalCards <= 500;

  return isValidRange;
};

export const oPCondition: Condition = (cardsList: Card[]) => {
  const totalCards = cardsList.length;
  const maxAllowedOP = Math.floor(totalCards / 2); // 50% of total cards

  const opCards = cardsList.filter(
    (card) =>
      (card.rarity === 2 && card.strength === 5) ||
      (card.rarity === 1 && card.strength === 4) ||
      (card.rarity === 0 && card.strength === 3)
  );

  const opCardCount = opCards.length;

  // Organize OP cards by rarity
  const opByRarity: Record<number, number> = { 0: 0, 1: 0, 2: 0 };
  const opByCatType: Record<number, number> = {};

  opCards.forEach((card) => {
    opByRarity[card.rarity] = (opByRarity[card.rarity] || 0) + 1;
    opByCatType[card.cattype] = (opByCatType[card.cattype] || 0) + 1;
  });

  // Calculate excess OP cards for detailed output
  const excessOPCards = Math.max(0, opCardCount - maxAllowedOP);
  const excessPerRarity = {
    0: Math.max(0, opByRarity[0] - maxAllowedOP / 3),
    1: Math.max(0, opByRarity[1] - maxAllowedOP / 3),
    2: Math.max(0, opByRarity[2] - maxAllowedOP / 3),
  };

  // Check if OP cards exceed the allowed threshold and return status
  if (opCardCount > maxAllowedOP) {
    return {
      status: false,
      message: `OP card count exceeds the maximum allowed (${maxAllowedOP} OP cards).`,
      details: {
        totalCards,
        maxAllowedOP,
        opCardCount,
        opByRarity,
        opByCatType,
        excessOPCards,
        excessPerRarity,
      },
    };
  }

  // If the condition is met, return success
  return {
    status: true,
    message: "OP condition met.",
    details: {
      totalCards,
      maxAllowedOP,
      opCardCount,
      opByRarity,
      opByCatType,
      excessOPCards,
      excessPerRarity,
    },
  };
};

//OLD OP VERSION TO CHECK EACH RARITY TYPE WITH STRENGTH 5 4 3
// export const oPCondition: Condition = (cardsList: Card[]) => {
// 	const totalCards = cardsList.length;
// 	const maxAllowedOP = Math.floor(totalCards / 2); // 50% of total cards

// 	const opCards = cardsList.filter(
// 	  (card) =>
// 		(card.rarity === 2 && card.strength === 5) ||
// 		(card.rarity === 1 && card.strength === 4) ||
// 		(card.rarity === 0 && card.strength === 3)
// 	);

// 	const opCardCount = opCards.length;

// 	// Organize OP cards by rarity
// 	const opByRarity: Record<number, number> = { 0: 0, 1: 0, 2: 0 };
// 	opCards.forEach((card) => {
// 	  opByRarity[card.rarity] = (opByRarity[card.rarity] || 0) + 1;
// 	});

// 	// Calculate how many of each rarity need to be removed if the condition fails
// 	const excessOPCards = Math.max(0, opCardCount - maxAllowedOP);
// 	const opByCatType: Record<number, number> = {};
// 	opCards.forEach((card) => {
// 	  opByCatType[card.cattype] = (opByCatType[card.cattype] || 0) + 1;
// 	});

// 	// Check if any cattype has more than the allowed OP cards
// 	const maxAllowedPerCatType = Math.floor(maxAllowedOP / 3);
// 	for (const cattype in opByCatType) {
// 	  if (opByCatType[cattype] > maxAllowedPerCatType) {
// 		return {
// 		  status: false,
// 		  message: `OP cards in cattype ${cattype} exceed the maximum allowed (${maxAllowedPerCatType} OP cards).`,
// 		  details: {
// 			totalCards,
// 			maxAllowedOP,
// 			opCardCount,
// 			opByRarity,
// 			opByCatType,
// 			excessOPCards,
// 			excessPerRarity: {
// 			  0: Math.max(0, opByRarity[0] - maxAllowedOP / 3),
// 			  1: Math.max(0, opByRarity[1] - maxAllowedOP / 3),
// 			  2: Math.max(0, opByRarity[2] - maxAllowedOP / 3),
// 			},
// 		  },
// 		};
// 	  }
// 	}

// 	// Check if OP cards exceed the overall allowed threshold
// 	if (excessOPCards > 0) {
// 	  return {
// 		status: false,
// 		message: `OP card count exceeds the maximum allowed (${maxAllowedOP} OP cards).`,
// 		details: {
// 		  totalCards,
// 		  maxAllowedOP,
// 		  opCardCount,
// 		  opByRarity,
// 		  opByCatType,
// 		  excessOPCards,
// 		  excessPerRarity: {
// 			0: Math.max(0, opByRarity[0] - maxAllowedOP / 3),
// 			1: Math.max(0, opByRarity[1] - maxAllowedOP / 3),
// 			2: Math.max(0, opByRarity[2] - maxAllowedOP / 3),
// 		  },
// 		},
// 	  };
// 	}

// 	// If all checks pass, return success
// 	return {
// 	  status: true,
// 	  message: "OP condition met.",
// 	  details: {
// 		totalCards,
// 		maxAllowedOP,
// 		opCardCount,
// 		opByRarity,
// 		opByCatType,
// 	  },
// 	};
//   };
