export const deckBuilderCards = [
  {
    id: 15639,
    cardType: 55,
    image: "cardsvgs/55.svg",
    name: "Secret Agent Stache",
    rarity: 1,
    cattype: 0,
    strength: 0,
  },
  {
    id: 15657,
    cardType: 373,
    image: "cardsvgs/373.svg",
    name: "Bulletproof Vest",
    rarity: 1,
    cattype: 2,
    strength: 4,
  },
  {
    id: 15649,
    cardType: 374,
    image: "cardsvgs/374.svg",
    name: "Carbon Baseball Helmet",
    rarity: 1,
    cattype: 2,
    strength: 2,
  },
  {
    id: 7060,
    cardType: 363,
    image: "cardsvgs/363.svg",
    name: "Azzerpardi's Pizza",
    rarity: 1,
    cattype: 2,
    strength: 4,
  },
  {
    id: 15652,
    cardType: 301,
    image: "cardsvgs/301.svg",
    name: "Baseball Helmet",
    rarity: 0,
    cattype: 2,
    strength: 3,
  },
  {
    id: 15645,
    cardType: 332,
    image: "cardsvgs/332.svg",
    name: "Machete",
    rarity: 0,
    cattype: 1,
    strength: 3,
  },
  {
    id: 11460,
    cardType: 363,
    image: "cardsvgs/363.svg",
    name: "Azzerpardi's Pizza",
    rarity: 1,
    cattype: 2,
    strength: 4,
  },
  {
    id: 14371,
    cardType: 356,
    image: "mutantsvgs/356.svg",
    name: "Sloppy Pig",
    rarity: 1,
    cattype: 0,
    strength: 0,
  },
  {
    id: 15647,
    cardType: 344,
    image: "cardsvgs/344.svg",
    name: "Stilleto",
    rarity: 0,
    cattype: 1,
    strength: 3,
  },
  {
    id: 3418,
    cardType: 12,
    image: "cardsvgs/12.svg",
    name: "Hatori Hanzo Sword",
    rarity: 1,
    cattype: 1,
    strength: 4,
  },
  {
    id: 8327,
    cardType: 120,
    image: "cardsvgs/120.svg",
    name: "Fantasy Hammer",
    rarity: 2,
    cattype: 1,
    strength: 4,
  },
  {
    id: 15638,
    cardType: 195,
    image: "cardsvgs/195.svg",
    name: "Gold Digger",
    rarity: 0,
    cattype: 0,
    strength: 0,
  },
  {
    id: 15632,
    cardType: 26,
    image: "cardsvgs/26.svg",
    name: "Ben Armstrong",
    rarity: 1,
    cattype: 0,
    strength: 0,
  },
  {
    id: 15633,
    cardType: 43,
    image: "cardsvgs/43.svg",
    name: "Jim Cramer",
    rarity: 1,
    cattype: 0,
    strength: 0,
  },
  {
    id: 15655,
    cardType: 306,
    image: "cardsvgs/306.svg",
    name: "Hockey Mask",
    rarity: 0,
    cattype: 2,
    strength: 3,
  },
  {
    id: 11452,
    cardType: 382,
    image: "cardsvgs/382.svg",
    name: "Golden Baseball Bat",
    rarity: 1,
    cattype: 1,
    strength: 3,
  },
  {
    id: 8336,
    cardType: 310,
    image: "cardsvgs/310.svg",
    name: "Painkillers",
    rarity: 0,
    cattype: 2,
    strength: 3,
  },
  {
    id: 8333,
    cardType: 303,
    image: "cardsvgs/303.svg",
    name: "Broken War Helmet",
    rarity: 0,
    cattype: 2,
    strength: 3,
  },
  {
    id: 8338,
    cardType: 361,
    image: "cardsvgs/361.svg",
    name: "Tactical Shield",
    rarity: 1,
    cattype: 2,
    strength: 4,
  },
  {
    id: 7040,
    cardType: 361,
    image: "cardsvgs/361.svg",
    name: "Tactical Shield",
    rarity: 1,
    cattype: 2,
    strength: 4,
  },
  {
    id: 15641,
    cardType: 18,
    image: "cardsvgs/18.svg",
    name: "Steel Spiked Bat",
    rarity: 1,
    cattype: 1,
    strength: 3,
  },
  {
    id: 15646,
    cardType: 365,
    image: "cardsvgs/365.svg",
    name: "Light Saber",
    rarity: 1,
    cattype: 1,
    strength: 4,
  },
  {
    id: 15654,
    cardType: 4,
    image: "cardsvgs/4.svg",
    name: "Taser Gun",
    rarity: 1,
    cattype: 2,
    strength: 4,
  },
  {
    id: 11658,
    cardType: 10,
    image: "cardsvgs/10.svg",
    name: "Flamethrower",
    rarity: 1,
    cattype: 1,
    strength: 4,
  },
  {
    id: 6614,
    cardType: 110,
    image: "cardsvgs/110.svg",
    name: "Cool Viking Helmet",
    rarity: 2,
    cattype: 2,
    strength: 5,
  },
  {
    id: 11443,
    cardType: 297,
    image: "cardsvgs/297.svg",
    name: "Xavier",
    rarity: 0,
    cattype: 0,
    strength: 0,
  },
  {
    id: 15642,
    cardType: 12,
    image: "cardsvgs/12.svg",
    name: "Hatori Hanzo Sword",
    rarity: 1,
    cattype: 1,
    strength: 4,
  },
  {
    id: 8337,
    cardType: 301,
    image: "cardsvgs/301.svg",
    name: "Baseball Helmet",
    rarity: 0,
    cattype: 2,
    strength: 3,
  },
  {
    id: 15643,
    cardType: 317,
    image: "cardsvgs/317.svg",
    name: "Boxing Glove",
    rarity: 0,
    cattype: 1,
    strength: 3,
  },
  {
    id: 15631,
    cardType: 213,
    image: "cardsvgs/213.svg",
    name: "James",
    rarity: 0,
    cattype: 0,
    strength: 0,
  },
  {
    id: 15640,
    cardType: 370,
    image: "cardsvgs/370.svg",
    name: "Wolverine Claw",
    rarity: 1,
    cattype: 1,
    strength: 3,
  },
  {
    id: 3430,
    cardType: 126,
    image: "cardsvgs/126.svg",
    name: "Missile Launcher",
    rarity: 2,
    cattype: 1,
    strength: 5,
  },
  {
    id: 11447,
    cardType: 31,
    image: "cardsvgs/31.svg",
    name: "Christine Lagarde",
    rarity: 1,
    cattype: 0,
    strength: 0,
  },
  {
    id: 11450,
    cardType: 17,
    image: "cardsvgs/17.svg",
    name: "Sawed Off Shotgun",
    rarity: 1,
    cattype: 1,
    strength: 3,
  },
  {
    id: 7043,
    cardType: 110,
    image: "cardsvgs/110.svg",
    name: "Cool Viking Helmet",
    rarity: 2,
    cattype: 2,
    strength: 5,
  },
  {
    id: 11655,
    cardType: 341,
    image: "cardsvgs/341.svg",
    name: "Spider",
    rarity: 0,
    cattype: 1,
    strength: 3,
  },
  {
    id: 11456,
    cardType: 389,
    image: "cardsvgs/389.svg",
    name: "Tree",
    rarity: 0,
    cattype: 2,
    strength: 3,
  },
  {
    id: 7051,
    cardType: 341,
    image: "cardsvgs/341.svg",
    name: "Spider",
    rarity: 0,
    cattype: 1,
    strength: 3,
  },
  {
    id: 6916,
    cardType: 15,
    image: "cardsvgs/15.svg",
    name: "Poison Dart",
    rarity: 1,
    cattype: 1,
    strength: 3,
  },
];
