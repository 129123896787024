import React from "react";

const BorderCorners = () => {
  return (
    <>
      <span className="topCornerLeftDots"></span>
      <span className="topCornerRightDots"></span>
      <span className="bottomCornerLeftDots"></span>
      <span className="bottomCorneRightDots"></span>
    </>
  );
};

export default BorderCorners;
