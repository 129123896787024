import {
  thresholdCondition,
  cattypeRarityCondition,
  cardCountRangeCondition,
  oPCondition, // Import the function itself
} from "../condtions";
import { Card } from "../types";

export const isUserAvailableToPlay = (
  cardsList: Card[],
  conditionNames: string[]
): { [key: string]: { status: boolean; message: string; details?: any } } => {
  // Define the conditions in the object and assign the function directly
  //   const conditionResult = oPCondition(cardsList); // Call the function with cardsList
  //   console.log("conditionResult", conditionResult);
  const conditions: any = {
    threshold: thresholdCondition,
    cattypeRarity: cattypeRarityCondition,
    cardCountRange: cardCountRangeCondition,
    oPCondition: oPCondition, // Store the function, not the result
  };

  const result: {
    [key: string]: { status: boolean; message: string; details?: any };
  } = {};

  conditionNames.forEach((conditionName) => {
    const conditionFunction = conditions[conditionName];
    if (conditionFunction) {
      const conditionResult = conditionFunction(cardsList); // Call the function with cardsList
      console.log(
        "conditionResult....",
        conditionName,
        conditionResult,
        conditionResult.status
      );
      if (
        conditionName === "oPCondition"
          ? conditionResult.status
          : conditionName === "cattypeRarity"
          ? conditionResult.status
          : conditionResult
      ) {
        result[conditionName] = {
          status: true,
          message: "Condition met",
          details: conditionResult?.details,
        };
      } else {
        // Handle failure cases with detailed messages
        if (conditionName === "threshold") {
          const totalCards = cardsList.length;
          const threshold = totalCards / 5;
          const cardsByCatType: Record<number, number> = cardsList.reduce(
            (acc, card) => {
              acc[card.cattype] = (acc[card.cattype] || 0) + 1;
              return acc;
            },
            {} as Record<number, number>
          );

          result[conditionName] = {
            status: false,
            message: `Threshold condition not met. Improvement needed.`,
            details: {
              totalCards,
              threshold,
              cardsByCatType,
            },
          };
        } else if (conditionName === "cattypeRarity") {
          const cattypeRarityCounts: Record<string, number> = {
            cattype0_rarity0: 0,
            cattype0_rarity1: 0,
            cattype0_rarity2: 0,
            cattype1_rarity0: 0,
            cattype1_rarity1: 0,
            cattype1_rarity2: 0,
            cattype2_rarity0: 0,
            cattype2_rarity1: 0,
            cattype2_rarity2: 0,
          };

          // Count each cattype/rarity combination in the cardsList
          cardsList.forEach((card) => {
            const { cattype, rarity } = card;
            const key = `cattype${cattype}_rarity${rarity}`;
            if (cattypeRarityCounts[key] !== undefined) {
              cattypeRarityCounts[key] += 1;
            }
          });

          const totalCards = cardsList.length;
          const DECK_SIZE = 13;
          const deckCount = Math.floor(totalCards / DECK_SIZE) || 1;

          const minRequiredPerCattypeAndRarity = Object.keys(
            cattypeRarityCounts
          ).reduce((acc: Record<string, number>, key) => {
            acc[key] = deckCount;
            return acc;
          }, {});

          result[conditionName] = {
            status: false,
            message: `Cattype/Rarity condition not met. Improvement needed.`,
            details: {
              cattypeRarityCounts,
              minRequiredPerCattypeAndRarity,
              deckCount,
            },
          };
        } else if (conditionName === "cardCountRange") {
          const totalCards = cardsList.length;

          result[conditionName] = {
            status: false,
            message: `Card count must be between 27 and 500. Improvement needed.`,
            details: {
              totalCards,
            },
          };
        } else if (conditionName === "oPCondition") {
          const conditionDetails = conditionResult.details || {};
          result[conditionName] = {
            status: conditionResult.status,
            message: conditionResult.message,
            details: conditionDetails,
          };
        }
      }
    } else {
      result[conditionName] = {
        status: false,
        message: `Condition "${conditionName}" does not exist.`,
      };
    }
  });
  return result;
};
